import React, {useState} from "react";
import '../../i18n/i18n'
import {QueryClient, QueryClientProvider} from "react-query";
import {AppProvider} from "../../contexts/AppContext";
import {QuizProvider} from "../../contexts/QuizContext";
import {DemoProvider} from "../../contexts/DemoContext";

export default function Providers({children}: { children: React.ReactNode }) {
    const [queryClient] = useState(() => new QueryClient());
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <QuizProvider>
                        <DemoProvider>
                            {children}
                        </DemoProvider>
                    </QuizProvider>
                </AppProvider>
            </QueryClientProvider>
        </>
    )
}