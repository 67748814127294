import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import gsap from "gsap";
import clsx from "clsx";
import {useQueries} from "react-query";
import apiClient from "../../utils/apiClient";
import {AppContext} from "../../contexts/AppContext";
import {ERROR, PRIZES_VIEW, PURCHASE_VIEW} from "../../config/constants";
import {RoundType} from "../../types/Round";
import Leaderboard from "./Leaderboard/Leaderboard";
import styles from './TopScore.module.scss';
import Button from "../elements/Button/Button";
import {QuizContext} from "../../contexts/QuizContext";
import {ReactComponent as TopScoreButtonIcon} from "../../assets/icons/top-scores-caret-down.svg";
import {calculateNumberOfDays, orderArrayByDateDescending} from "../../utils/dates";
import QuizWinners from "./QuizWinners/QuizWinners";
import SoundManager from "../../utils/SoundManager";

const TopScore = () => {
    const {t} = useTranslation();
    const {setCurrentModal} = useContext(AppContext);
    const {setCurrentView} = useContext(QuizContext);

    const [page, setPage] = useState('leaderboard')
    const [activeListLeaderBoard, setActiveListLeaderBoard] = useState('today');

    const [listDay, setListDay] = useState<any>([]);
    const [listWeek, setListWeek] = useState<any>([]);

    const [historicalListTypeWeek, setHistoricalListTypeWeek] = useState<any>([]);
    const [historicalListTypeDay, setHistoricalListTypeDay] = useState<any>([]);

    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const forthRef = useRef(null);

    const navButtons = [
        {id: 'today', label: t('topscore.today')},
        {id: 'week', label: t('topscore.week')},
    ];

    function getHistoricalCompetitionLists(array: any[]) {
        const historicalCompetitionsTypeWeek: any[] = [];
        const historicalCompetitionsTypeDay: any[] = [];

        array?.forEach((competition) => {
            competition.rounds?.forEach((round: { endTime: string | number | Date; startTime: string | number | Date; }) => {
                if (calculateNumberOfDays(round.endTime, round.startTime) > 1) {
                    historicalCompetitionsTypeWeek.push(round);
                } else {
                    historicalCompetitionsTypeDay.push(round);
                }
            });
        });

        return {
            typeWeek: historicalCompetitionsTypeWeek,
            typeDay: historicalCompetitionsTypeDay,
        };
    }

    useQueries([
        {
            queryKey: 'highscore',
            queryFn: () =>
                apiClient.get(
                    'api/Quiz/Competitions?includeLeaderboards=true&includeHistorical=true', {}),
            onSuccess: (response: any) => {
                const dateNow = new Date();

                const activeCompetition = response.find(
                    (competition: { startTime: string | number | Date; endTime: string | number | Date; }) =>
                        dateNow > new Date(competition.startTime) &&
                        dateNow < new Date(competition.endTime)
                );

                const historicalCompetitions = [...response];

                const { typeDay, typeWeek } = getHistoricalCompetitionLists(
                    historicalCompetitions
                );

                setHistoricalListTypeDay(orderArrayByDateDescending(typeDay));
                setHistoricalListTypeWeek(orderArrayByDateDescending(typeWeek));


                const activeRounds = activeCompetition?.rounds.filter(
                    (r: { isActive: any; }) => r.isActive
                );

                let dayRound: RoundType = {
                    endTime: new Date(),
                    startTime: new Date(new Date().setDate(new Date().getDate() - 1)),
                    competitionType: ''
                };
                let weekRound: RoundType = {
                    endTime: new Date(),
                    startTime: new Date(),
                    competitionType: ''
                };

                activeRounds.forEach((round: {
                    endTime: string | number | Date;
                    startTime: string | number | Date;
                    competitionType?: string;
                }) => {

                    if (!dayRound) {
                        dayRound = round;
                        weekRound = round;
                    } else {
                        const lengthOfDayRound =
                            new Date(dayRound.endTime).getTime() -
                            new Date(dayRound.startTime).getTime();
                        const lengthOfWeekRound =
                            new Date(weekRound.endTime).getTime() -
                            new Date(weekRound.startTime).getTime();
                        const lengthOfRound =
                            new Date(round.endTime).getTime() -
                            new Date(round.startTime).getTime();

                        if (lengthOfRound < lengthOfDayRound) {
                            dayRound = round;
                        }
                        if (lengthOfRound > lengthOfWeekRound) {
                            weekRound = round;
                        }
                    }
                });
                if (dayRound) {
                    dayRound.competitionType = 'day';
                    setListDay(dayRound);
                }

                if (weekRound) {
                    weekRound.competitionType = 'week';
                    setListWeek(weekRound);
                }
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
            refetchOnWindowFocus: false,
        },
    ]);

    useEffect(() => {
        gsap.fromTo(firstRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay: 0.5});
        gsap.fromTo(secondRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay: 1});
        gsap.fromTo(thirdRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay: 1.5});
    }, []);

    return (
        <div className={styles.page}>

            {page === 'leaderboard' ?
                    <h2 className={styles.heading} ref={firstRef}>{t('topscore.leaderboard')}</h2>
                    :
                    <h2 className={styles.heading} ref={firstRef}>{t('topscore.quizWinnersLabel')}</h2>
            }

            <div className={styles.buttonBox} ref={secondRef}>
                {navButtons.map(({id, label}) => (
                    <button
                        key={label}
                        type="button"
                        className={clsx(styles.navButton, {
                            [styles.isActive]: activeListLeaderBoard === id,
                        })}
                        onClick={() => {
                            setActiveListLeaderBoard(id);
                        }}
                    >
                        {label}
                    </button>
                ))}
            </div>

            <div className={styles.tableBox} ref={thirdRef}>
                {page === 'leaderboard' && (
                    activeListLeaderBoard === 'today' &&
                    <Leaderboard leaders={listDay && listDay.leaderboard}/>
                )}

                {page === 'leaderboard' && (
                    activeListLeaderBoard === 'week' &&
                    <Leaderboard leaders={listWeek && listWeek.leaderboard}/>
                )}

                {page === 'quizWinners' &&
                    activeListLeaderBoard === 'week' &&
                    <QuizWinners winners={historicalListTypeWeek} isListTypeWeek={true}/>
                }

                {page === 'quizWinners' &&
                    activeListLeaderBoard === 'today' &&
                    <QuizWinners winners={historicalListTypeDay} isListTypeWeek={false}/>
                }
            </div>

            <div>
                {page === 'leaderboard' ?
                    <button
                        className={styles.button}
                        onClick={() => {
                            setPage('quizWinners');
                        }}
                    >
                        <TopScoreButtonIcon className={styles.winnersIcon}/>
                        {t('topscore.quizWinnersLabel')}
                    </button>
                    :
                    <button
                        className={styles.button}
                        onClick={() => {
                            setPage('leaderboard');
                        }}
                    >
                        <TopScoreButtonIcon className={styles.leaderboardIcon}/>
                        {t('topscore.leaderboard')}
                    </button>
                }
            </div>

            <div className={styles.dateBox}>
                {page === 'leaderboard' &&
                activeListLeaderBoard === 'today' &&
                    <p className={styles.dateLabel}>{t('topscore.today')} {t('topscore.competitionEnd')}</p>
                }
                {page === 'leaderboard' &&
                activeListLeaderBoard === 'week' &&
                    <p className={styles.dateLabel}>{t('topscore.week')} {t('topscore.competitionEnd')}</p>
                }

                {page === 'leaderboard' &&
                    activeListLeaderBoard === 'today' &&
                    <p className={styles.date}>
                        {listDay && listDay.endTime &&
                            (() => {
                                let date = new Date(listDay.endTime);
                                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} at ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
                            })()
                        }
                    </p>
                }
                {page === 'leaderboard' &&
                activeListLeaderBoard === 'week' &&
                    <p className={styles.date}>{listWeek && listWeek.endTime && new Date(listWeek.endTime).toLocaleDateString()}</p>
                }
            </div>

            <div className={styles.bottomButtonBox} ref={forthRef}>
                <Button
                    className={styles.prizesButton}
                    isSecondary
                    onClick={() => {
                        SoundManager.instance.playVO('button');
                        setCurrentView(PRIZES_VIEW)
                    }}
                >
                    {t('topscore.seePrizes')}
                </Button>
                <Button
                    className={styles.playButton}
                    onClick={() => {
                        SoundManager.instance.playVO('button');
                        setCurrentView(PURCHASE_VIEW)
                    }}
                >
                    {t('start.playButton')}
                    <p className={styles.buttonGameName}>{t('start.gameName')}</p>
                </Button>
            </div>
        </div>
    );
};

export default TopScore;