import React, {useState, createContext, ReactNode} from 'react';
import {START_VIEW} from "../config/constants";
import {HighscoreType} from "../types/Highscore";
import {UnfinishedTicket} from "../types/UnfinishedTickets";

interface QuizContextProps {
    currentView: string;
    setCurrentView: React.Dispatch<React.SetStateAction<string>>;
    accumulatedScore: number | null;
    setAccumulatedScore: React.Dispatch<React.SetStateAction<number | null>>;
    questionScore: number | null;
    setQuestionScore: React.Dispatch<React.SetStateAction<number | null>>;
    coinIsFlipped: boolean;
    setCoinIsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
    currentTicket: any;
    setCurrentTicket: React.Dispatch<React.SetStateAction<any>>;
    quizIsSkipped: boolean;
    setQuizIsSkipped: React.Dispatch<React.SetStateAction<boolean>>;
    lotteryResult: any;
    setLotteryResult: React.Dispatch<React.SetStateAction<any>>;
    coins: { value: any }[];
    setCoins: React.Dispatch<React.SetStateAction<{ value: any }[]>>;
    questionNumber: number;
    setQuestionNumber: React.Dispatch<React.SetStateAction<number>>;
    nextCategory: any;
    setNextCategory: React.Dispatch<React.SetStateAction<any>>;
    nextQuestion: any;
    setNextQuestion: React.Dispatch<React.SetStateAction<any>>;
    questionsSummary: any;
    setQuestionSummary: React.Dispatch<React.SetStateAction<any>>;
    userIsPlayingQuiz: boolean;
    setUserIsPlayingQuiz: React.Dispatch<React.SetStateAction<boolean>>;
    correctAnswerFeedbackIndex: number;
    setCorrectAnswerFeedbackIndex: React.Dispatch<React.SetStateAction<number>>;
    incorrectAnswerFeedbackIndex: number;
    setIncorrectAnswerFeedbackIndex: React.Dispatch<React.SetStateAction<number>>;
    incorrectAnsweredQuestions: number;
    setIncorrectAnsweredQuestions: React.Dispatch<React.SetStateAction<number>>;
    helperRemainderDisplayed: boolean;
    setHelperRemainderDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
    highscore: HighscoreType | null;
    setHighscore: React.Dispatch<React.SetStateAction<HighscoreType | null>>;
    unfinishedTicket: any;
    setUnfinishedTicket: React.Dispatch<React.SetStateAction<any>>;
    ticketStatus: string | null;
    setTicketStatus: React.Dispatch<React.SetStateAction<string | null>>;
    ticketResult: TicketResult | null;
    setTicketResult: React.Dispatch<React.SetStateAction<any>>;
    ticketQuestion: number | null;
    setTicketQuestion: React.Dispatch<React.SetStateAction<number | null>>;
    activatedTicket: any;
    setActivatedTicket: React.Dispatch<React.SetStateAction<any>>;
}

interface QuizProviderProps {
    children: ReactNode;
    value: Partial<QuizContextProps>;
}

interface TicketResult {
    arrayLayout: any[];
}

const initialQuizContextProps: QuizContextProps = {
    currentView: START_VIEW,
    setCurrentView: () => {},
    accumulatedScore: null,
    setAccumulatedScore: () => {},
    questionScore: null,
    setQuestionScore: () => {},
    coinIsFlipped: false,
    setCoinIsFlipped: () => {},
    currentTicket: null,
    setCurrentTicket: () => {},
    quizIsSkipped: false,
    setQuizIsSkipped: () => {},
    lotteryResult: null,
    setLotteryResult: () => {},
    coins: [],
    setCoins: () => {},
    questionNumber: 0,
    setQuestionNumber: () => {},
    nextCategory: null,
    setNextCategory: () => {},
    nextQuestion: null,
    setNextQuestion: () => {},
    questionsSummary: null,
    setQuestionSummary: () => {},
    userIsPlayingQuiz: false,
    setUserIsPlayingQuiz: () => {},
    correctAnswerFeedbackIndex: 0,
    setCorrectAnswerFeedbackIndex: () => {},
    incorrectAnswerFeedbackIndex: 0,
    setIncorrectAnswerFeedbackIndex: () => {},
    incorrectAnsweredQuestions: 0,
    setIncorrectAnsweredQuestions: () => {},
    helperRemainderDisplayed: false,
    setHelperRemainderDisplayed: () => {},
    highscore: null,
    setHighscore: () => {},
    unfinishedTicket: null,
    setUnfinishedTicket: () => {},
    ticketStatus: null,
    setTicketStatus: () => {},
    ticketResult: null,
    setTicketResult: () => {},
    ticketQuestion: null,
    setTicketQuestion: () => {},
    activatedTicket: null,
    setActivatedTicket: () => {},
};

const QuizContext = createContext<QuizContextProps>(initialQuizContextProps);

function QuizProvider({children, value}: QuizProviderProps) {
    const [currentView, setCurrentView] = useState(START_VIEW);
    const [currentTicket, setCurrentTicket] = useState<any>(null);
    const [accumulatedScore, setAccumulatedScore] = useState<number | null>(null);
    const [questionScore, setQuestionScore] = useState<number | null>(null);
    const [coinIsFlipped, setCoinIsFlipped] = useState<boolean>(false);
    const [quizIsSkipped, setQuizIsSkipped] = useState<boolean>(false);
    const [lotteryResult, setLotteryResult] = useState<any>(null);
    const [coins, setCoins] = useState<{ value: any }[]>([]);
    const [questionNumber, setQuestionNumber] = useState<number>(0);
    const [nextCategory, setNextCategory] = useState<any>(null);
    const [nextQuestion, setNextQuestion] = useState<any>(null);
    const [questionsSummary, setQuestionSummary] = useState<any>(null);
    const [userIsPlayingQuiz, setUserIsPlayingQuiz] = useState<boolean>(false);
    const [correctAnswerFeedbackIndex, setCorrectAnswerFeedbackIndex] = useState<number>(0);
    const [incorrectAnswerFeedbackIndex, setIncorrectAnswerFeedbackIndex] = useState<number>(0);
    const [incorrectAnsweredQuestions, setIncorrectAnsweredQuestions] = useState<number>(0);
    const [helperRemainderDisplayed, setHelperRemainderDisplayed] = useState<boolean>(false);
    const [highscore, setHighscore] = useState<HighscoreType | null>(null);
    const [unfinishedTicket, setUnfinishedTicket] = useState<UnfinishedTicket | null>(null);
    const [ticketStatus, setTicketStatus] = useState<string | null>(null);
    const [ticketResult, setTicketResult] = useState<TicketResult | null>(null);
    const [ticketQuestion, setTicketQuestion] = useState<number | null>(null);
    const [activatedTicket, setActivatedTicket] = useState(null);

    return (
        <QuizContext.Provider
            value={{
                ...value,
                currentView,
                setCurrentView,
                accumulatedScore,
                setAccumulatedScore,
                questionScore,
                setQuestionScore,
                coinIsFlipped,
                setCoinIsFlipped,
                currentTicket,
                setCurrentTicket,
                quizIsSkipped,
                setQuizIsSkipped,
                lotteryResult,
                setLotteryResult,
                coins,
                setCoins,
                questionNumber,
                setQuestionNumber,
                nextCategory,
                setNextCategory,
                nextQuestion,
                setNextQuestion,
                questionsSummary,
                setQuestionSummary,
                userIsPlayingQuiz,
                setUserIsPlayingQuiz,
                correctAnswerFeedbackIndex,
                setCorrectAnswerFeedbackIndex,
                incorrectAnswerFeedbackIndex,
                setIncorrectAnswerFeedbackIndex,
                incorrectAnsweredQuestions,
                setIncorrectAnsweredQuestions,
                helperRemainderDisplayed,
                setHelperRemainderDisplayed,
                highscore,
                setHighscore,
                unfinishedTicket,
                setUnfinishedTicket,
                ticketStatus,
                setTicketStatus,
                ticketResult,
                setTicketResult,
                ticketQuestion,
                setTicketQuestion,
                activatedTicket,
                setActivatedTicket,
            }}
        >
            {children}
        </QuizContext.Provider>
    );
}

QuizProvider.defaultProps = {
    value: {
        quizStatus: null,
        questionNumber: 0,
    },
};

export {QuizContext, QuizProvider};
