import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {QuizContext} from "../../../contexts/QuizContext";
import {LOTTERY_RESULT_VIEW} from "../../../config/constants";
import {motion} from 'framer-motion';
import styles from './Winning.module.scss'
import getWinningSymbol from "../../../utils/getWinningSymbol";
import SoundManager from "../../../utils/SoundManager";
import formatCurrency from "../../../utils/formatCurrency";

function Winning() {
    const {t} = useTranslation();
    const {setCurrentView, lotteryResult} = useContext(QuizContext);

    useEffect(() => {
        SoundManager.instance.stopVO();
        SoundManager.instance.playVO('winning');
        const timer = setTimeout(() => {
            setCurrentView(LOTTERY_RESULT_VIEW);
        }, 8000);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.page}>

            <motion.div
                className={styles.headingBox}
                initial={{y: -50, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 1}}>
                <h2 className={styles.heading}>{t('quiz.summary.heading')}</h2>
                <p className={styles.subHeading}>{t('quiz.summary.subHeading')}</p>
            </motion.div>

            <motion.div
                className={styles.winningBox}
                initial={{y: -50, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 1, delay: 0.5}}>
                <h1 className={styles.winningHeading}>{t('quiz.summary.winningHeading')}</h1>
                <motion.h1
                    className={styles.winningAmount}
                    initial={{scale: 1}}
                    animate={{scale: [1, 1.1, 1], rotate: [0, 10, -10, 0]}}
                    transition={{duration: 0.8, delay: 3}}
                >
                    {formatCurrency(lotteryResult.prizeValue)}
                </motion.h1>
            </motion.div>

            <div className={styles.coins}>
                <motion.div
                    className={styles.coin}
                    initial={{y: -50, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{duration: 1, delay: 1}}>
                    <p className={styles.coinValue}>{formatCurrency(lotteryResult.prizeValue)}</p>
                    <img src={getWinningSymbol(lotteryResult.prizeValue)} alt=""/>
                </motion.div>
                <motion.div
                    className={styles.coin}
                    initial={{y: -50, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{duration: 1, delay: 1.15}}>
                    <p className={styles.coinValue}>{formatCurrency(lotteryResult.prizeValue)}</p>
                    <img src={getWinningSymbol(lotteryResult.prizeValue)} alt=""/>
                </motion.div>
                <motion.div
                    className={styles.coin}
                    initial={{y: -50, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{duration: 1, delay: 1.3}}>
                    <p className={styles.coinValue}>{formatCurrency(lotteryResult.prizeValue)}</p>
                    <img src={getWinningSymbol(lotteryResult.prizeValue)} alt=""/>
                </motion.div>
            </div>

        </div>
    );
}

export default Winning;