import React, {useContext, useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import {QuizContext} from "../../../contexts/QuizContext";
import {AppContext} from "../../../contexts/AppContext";

//coins
import front from '../../../assets/coins/coin/coin-flip-front.png';
import right1 from '../../../assets/coins/coin/coin-right.png';
import right2 from '../../../assets/coins/coin/coin-right_2.png';
import side from '../../../assets/coins/coin/coin_side.png';
import left1 from '../../../assets/coins/coin/coin-left.png';
import left2 from '../../../assets/coins/coin/coin-left_2.png';
import back from '../../../assets/coins/coin/coin-flip-front.png';
import getWinningSymbol from "../../../utils/getWinningSymbol";
import SoundManager from "../../../utils/SoundManager";

interface CoinFlipAnimationProps {
    onClickCoin: () => number;
}

function CoinFlipAnimation({onClickCoin}: CoinFlipAnimationProps) {
    const imageRef = useRef<HTMLImageElement | null>(null);
    const {coinIsFlipped, setCoinIsFlipped} = useContext(QuizContext);
    const {isReplay} = useContext(AppContext);

    const startAnimation = () => {
        if (coinIsFlipped || isReplay) return;
        const animation = gsap.timeline();
        if (SoundManager.instance.currentSound !== 'coin-flip') {
            SoundManager.instance.playVO('coin-flip');
        }
        animation.to(imageRef.current, {
            rotationY: 90, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                if (imageRef.current) imageRef.current.src = right1;
            }
        })
            .to(imageRef.current, {
                rotationY: 180, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = right2;
                }
            })
            .to(imageRef.current, {
                rotationY: 270, duration: 0.07, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = side;
                }
            })
            .to(imageRef.current, {
                rotationY: 360, duration: 0.07, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = left1;
                }
            })
            .to(imageRef.current, {
                rotationY: 450, duration: 0.07, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = left2;
                }
            })
            .to(imageRef.current, {
                rotationY: 540, duration: 0.07, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = back;
                }
            })
            .to(imageRef.current, {
                rotationY: 630, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = right1;
                }
            })
            .to(imageRef.current, {
                rotationY: 720, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = back;
                }
            })
            .to(imageRef.current, {
                rotationY: 630, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) {
                        const coinValue = onClickCoin();
                        imageRef.current.src = getWinningSymbol(coinValue, true);
                    }
                }
            })
            .to(imageRef.current, {
                rotationY: 720, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = getWinningSymbol(onClickCoin(), true);
                }
            })
            .to(imageRef.current, {
                rotationY: 810, duration: 0.07, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = side;
                }
            })
            .to(imageRef.current, {
                rotationY: 900, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = getWinningSymbol(onClickCoin(), true);
                    setCoinIsFlipped(true);
                }
            })
            .to(imageRef.current, {
                rotationY: 990, duration: 0.07, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = side;
                }
            })
            .to(imageRef.current, {
                rotationY: 1080, duration: 0.1, ease: "power4.inOut", onComplete: () => {
                    if (imageRef.current) imageRef.current.src = getWinningSymbol(onClickCoin(), true);
                    setCoinIsFlipped(true);
                }
            })

    };

    const handleClick = () => {
        startAnimation();
        onClickCoin();
    };

    useEffect(() => {
        if (isReplay && imageRef.current) {
            setCoinIsFlipped(true);
            const coinValue = onClickCoin();
            imageRef.current.src = getWinningSymbol(coinValue, true);
            gsap.to(imageRef.current, {scale: 1, opacity: 1, duration: 0.5, ease: "power4.inOut"});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReplay]);

    return (
        <img ref={imageRef} src={front} alt="Coin" onClick={handleClick}/>
    );
}

export default CoinFlipAnimation;