import React from 'react';
import clsx from 'clsx';
import { string, bool, func, number } from 'prop-types';

import {ReactComponent as Helper1} from '../../../assets/helpers/helper-extra-time.svg';
import {ReactComponent as Helper2} from '../../../assets/helpers/helper-question.svg';
import {ReactComponent as Helper3} from '../../../assets/helpers/helper-5050.svg';

import styles from './HelperButton.module.scss';

interface HelperButtonProps {
  onClick: () => void;
  isHelperExtraTime: boolean;
  isHelperNewQuestion: boolean;
  isHelperFiftyFifty: boolean;
  label: string;
  disabled: boolean;
  amountHelpers: number;
  isActive: boolean;
}

export default function HelperButton({
  onClick,
  isHelperExtraTime,
  isHelperNewQuestion,
  isHelperFiftyFifty,
  label,
  disabled,
  amountHelpers,
  isActive,
}: HelperButtonProps) {

  function renderIcon(helper: boolean) {
    switch (helper) {
      case isHelperExtraTime:
        return Helper1;
      case isHelperNewQuestion:
        return Helper2;
      case isHelperFiftyFifty:
        return Helper3;
      default:
        return null;
    }
  }

  const Icon = renderIcon(isHelperExtraTime || isHelperNewQuestion || isHelperFiftyFifty);

  return (
    <button
      type="button"
      disabled={disabled || amountHelpers === 0}
      className={clsx(styles.wrapper, {
        [styles.isHelperExtraTime]: isHelperExtraTime,
        [styles.isHelperNewQustion]: isHelperNewQuestion,
        [styles.isHelperFiftyFifty]: isHelperFiftyFifty,
        [styles.isActive]: isActive,
      })}
      onClick={onClick}
    >
      {amountHelpers !== 0 && (
        <div className={styles.amountHelpers}>
          <span>{amountHelpers}</span>
        </div>
      )}
      {Icon && <Icon className={clsx(styles.icon, {
        [styles.extraTimeIcon]: isHelperExtraTime,
        [styles.newQustionIcon]: isHelperNewQuestion,
        [styles.fiftyFiftyIcon]: isHelperFiftyFifty,
      })} />}
      <span className={styles.label}>{label}</span>
    </button>
  );
}

HelperButton.propTypes = {
  onClick: func,
  isHelperExtraTime: bool,
  isHelperNewQuestion: bool,
  isHelperFiftyFifty: bool,
  label: string,
  disabled: bool,
  amountHelpers: number,
  isActive: bool,
};

HelperButton.defaultProps = {
  onClick: null,
  isHelperExtraTime: false,
  isHelperNewQuestion: false,
  isHelperFiftyFifty: false,
  label: '',
  disabled: false,
  amountHelpers: 0,
  isActive: false,
};
