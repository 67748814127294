import React, {useContext} from 'react';
import styles from "./ReplayLotteryResult.module.scss";
import Button from "../../elements/Button/Button";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../contexts/AppContext";

const ReplayLotteryResult = ({lotteryResult, ticketFinalizeData}: any) => {
    const {t} = useTranslation();
    const {callback} = useContext(AppContext)

    const closeGame = () => {
        callback('close', '');
    }

    return (
        <div className={styles.page}>

            <h3 className={styles.header}>
                {t('quiz.lotteryResult.replayHeader')}
            </h3>

            <div className={styles.gameResultWrapper}>
                {lotteryResult.prizeValue === 0 ? (
                    <div className={styles.result}>
                        <p className={styles.noPrize}>{t('quiz.lotteryResult.noPrize')}</p>
                    </div>
                ) : (
                    <div className={clsx(styles.result, styles.isWinning)}>

                        <h3 className={styles.prizeHeader}>
                            {t('quiz.lotteryResult.lotteryHeader')}
                        </h3>
                        <h1 className={styles.prizeValue}>${lotteryResult.prizeValue}</h1>

                    </div>
                )}
            </div>

            <div className={clsx(styles.playAgainButtonBox,
                {[styles.noPrize]: lotteryResult.prizeValue === 0})}>

                <Button
                    isSecondary
                    onClick={closeGame}
                    isDisabled={!ticketFinalizeData}
                >
                    {t('quiz.lotteryResult.closeGame')}
                </Button>

            </div>

        </div>
    );
};

export default ReplayLotteryResult;