export default class MathUtils {

    static norm(value: number, min: number, max: number) {
        return (value - min) / (max - min);
    }

    static lerp(norm: number, min: number, max: number) {
        return (max - min) * norm + min;
    }

    static map(value: number, sourceMin: number, sourceMax: number, destMin: number, destMax: number) {
      return this.lerp(
            this.norm(value, sourceMin, sourceMax),
            destMin,
            destMax
        );
    }
}
