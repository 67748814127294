import React, {useContext, useEffect, useState} from 'react';
import styles from './App.module.scss'
import Menu from "./components/Menu/Menu";
import TopBar from "./components/TopBar/TopBar";
import {AnimatePresence, useCycle, motion} from "framer-motion";
import BottomMenu from "./components/BottomMenu/BottomMenu";
import Start from "./components/Start/Start";
import {AppContext} from "./contexts/AppContext";
import clientApi from "./utils/apiClient";
import Countdown from "./components/Quiz/Countdown/Countdown";
import Purchase from "./components/Quiz/Purchase/Purchase";
import Question from "./components/Quiz/Question/Question";
import ErrorModal from "./components/ErrorModal/ErrorModal";
import {QuizContext} from "./contexts/QuizContext";
import {
    CONFIRMATION_VIEW,
    COUNTDOWN_VIEW, ERROR,
    FLIP_COIN_VIEW, LOGIN,
    LOTTERY_RESULT_VIEW, PRIZES_VIEW, PURCHASE_VIEW,
    QUESTION_VIEW,
    RESULT_VIEW, START_VIEW, TOPSCORE_VIEW,
    TUTORIAL, WINNING_VIEW
} from "./config/constants";
import FlipCoin from "./components/Quiz/FlipCoin/FlipCoin";
import Tutorial from "./components/Tutorial/Tutorial";
import Winning from "./components/Quiz/Winning/Winning";
import QuizResult from "./components/Quiz/QuizResult/QuizResult";
import LotteryResult from "./components/Quiz/LotteryResult/LotteryResult";
import Prizes from "./components/Prizes/Prizes";
import SoundManager from "./utils/SoundManager";
import {useTranslation} from "react-i18next";
import TopScore from "./components/TopScore/TopScore";
import {DemoContext} from "./contexts/DemoContext";
import setInnerHeightVar from "./utils/innerHeightVariable";
import apiClient from "./utils/apiClient";
import adjustOrderOfCoins from "./utils/adjustOrderOfCoins";
import TurnDeviceScreen from "./components/TurnDeviceScreen/TurnDeviceScreen";

interface AppProps {
    initData: {
        apiURL: string;
        tokenData:
            | {
            access_token: string;
            refresh_token: string;
        }
            | null;
        hide: string[];
        replayTicket: string | null;
        demomode: number | null;
        stakes: { gameId: number; cost: number }[];
        callback_event: (eventType: string, eventData: any) => void;
        locale: {
            language: string | undefined;
            currency: string | undefined;
            region: string | undefined;
        };
    };
}

function App({initData}: AppProps): JSX.Element {
    const [open, cycleOpen] = useCycle(false, true);
    const {
        setTokenData,
        setStakes,
        setCurrentModal,
        currentModal,
        appIsMuted,
        setUserData,
        userData,
        setUserPaymentSuccess,
        setIsReplay,
        setCurrencyLocale,
        getReplayTicket,
        callback,
        setCallback,
        tokenData,
    } = useContext(AppContext);
    const {
        currentView,
        setCurrentView,
        coins,
        setCurrentTicket,
        setCoins,
        setQuestionNumber,
        setQuizIsSkipped,
        setAccumulatedScore,
        setQuestionSummary,
        setLotteryResult,
        setNextQuestion,
        setIncorrectAnsweredQuestions,
        setHelperRemainderDisplayed,
        setUserIsPlayingQuiz,
        setActivatedTicket,
        setCoinIsFlipped,
        setNextCategory,
    } = useContext(QuizContext);
    const {setIsFunMode, setFunModeBalance, isDemo, resetDemoBetweenRounds} = useContext(DemoContext);
    const {i18n} = useTranslation();

    const [showBalance, setShowBalance] = useState<boolean>(true);
    const [isFetchingUnfinishedTickets, setIsFetchingUnfinishedTickets] = useState(true);
    const [isMobileLandscape, setIsMobileLandscape] = useState<boolean>(
        window.innerHeight < 480
    );

    function checkBottomMenu(currentView: string | undefined): boolean {
        if (currentView === QUESTION_VIEW ||
            currentView === COUNTDOWN_VIEW ||
            currentView === FLIP_COIN_VIEW ||
            currentView === RESULT_VIEW ||
            currentView === LOTTERY_RESULT_VIEW ||
            currentView === RESULT_VIEW ||
            currentView === TUTORIAL ||
            currentView === WINNING_VIEW ||
            currentView === PURCHASE_VIEW ||
            currentView === CONFIRMATION_VIEW
        ) {
            return true;
        } else if (
            currentView === START_VIEW
        ) {
            return false;
        }
        return true;
    }

    const resetBetweenRounds = () => {
        setCurrentTicket(null);
        setCoins([]);
        setCurrentView(START_VIEW);
        setActivatedTicket(null);
        setUserPaymentSuccess(false);
        setUserIsPlayingQuiz(false);
        setQuestionNumber(1);
        setQuizIsSkipped(false);
        setAccumulatedScore(null);
        setQuestionSummary(null);
        setLotteryResult(null);
        setNextQuestion(null);
        setNextCategory(null);
        setIncorrectAnsweredQuestions(0);
        setHelperRemainderDisplayed(false);
        setIsReplay(false);
        setCoinIsFlipped(false);
    };

    const handleResize: () => void = () => {
        const isLandscape: boolean = window.matchMedia(
            '(orientation: landscape)'
        ).matches;

        setIsMobileLandscape(window.innerHeight < 480 && isLandscape);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        return () => {
            resetBetweenRounds();
            if (isDemo) {
                resetDemoBetweenRounds();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setInnerHeightVar();
        }

        if (!initData) return;
        const {
            stakes,
            tokenData,
            hide,
            apiURL,
            demomode,
            replayTicket,
            callback_event,
            locale,
        } = initData;
        if (stakes && setStakes) {
            setStakes(stakes);
        }
        clientApi.setBaseURL(apiURL, tokenData);

        if (tokenData) {
            setTokenData(tokenData);
        } else {
            setCurrentModal(LOGIN);
        }

        if (locale.language) {
            i18n.changeLanguage(locale.language);
        }

        if (locale.region) {
            setCurrencyLocale(locale.region);
        }

        if (replayTicket) {
            setIsReplay(true);
            getReplayTicket(replayTicket).then((response) => {
                setCurrentTicket(response);
                apiClient.get(`${response.tickets[0].url}`, {})
                    .then((response: any) => {
                        const data = {
                            ...response,
                            arrayLayout: adjustOrderOfCoins(response.layout, response.prizeValue),
                        };
                        setLotteryResult(data);
                        setCurrentView(FLIP_COIN_VIEW);
                    })
                    .catch((error) => {
                        console.error('Unhandled error in apiClient.get:', error);
                        setCurrentModal(ERROR);
                    });
            })
        }

        if (hide) {
            setShowBalance(!hide.includes('balance'));
        }

        if (typeof demomode == 'number' && !tokenData) {
            setIsFunMode(true);
            setFunModeBalance(demomode);
        }

        if (typeof callback_event === 'function') {
            setCallback(() => callback_event);
            // @ts-ignore
            setUserData(prevData => ({...prevData, callback_event}));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initData])

    useEffect(() => {
        if (appIsMuted) {
            SoundManager.instance.muteApp(true);
        } else {
            SoundManager.instance.muteApp(false);
        }

        if (callback) {
            callback('audioToggle', !appIsMuted);
        } else {
            console.warn('callback is not defined');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appIsMuted, userData, callback]);

    const variants = {
        hidden: {opacity: 0},
        visible: {opacity: 1},
    };

    return (
        <>
            {isMobileLandscape && <TurnDeviceScreen/>}
            {!isMobileLandscape &&
                <>
                    <TopBar resetBetweenRounds={resetBetweenRounds} cycleOpen={cycleOpen} displayBalance={showBalance}/>
                    <AnimatePresence mode={'wait'}>
                        <Menu isOpen={open} cycleOpen={cycleOpen}/>
                        <motion.div
                            className={styles.App}
                            key={currentView}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={variants}
                            transition={{duration: 0.2}}
                        >
                            {currentView === START_VIEW && (
                                <Start resetBetweenRounds={resetBetweenRounds}
                                       setIsFetchingUnfinishedTickets={setIsFetchingUnfinishedTickets}/>
                            )}
                            {currentView === PURCHASE_VIEW && <Purchase/>}
                            {currentView === COUNTDOWN_VIEW && <Countdown/>}
                            {currentView === QUESTION_VIEW && <Question/>}
                            {currentView === FLIP_COIN_VIEW && <FlipCoin/>}
                            {currentView === TUTORIAL && <Tutorial/>}
                            {currentView === WINNING_VIEW && <Winning/>}
                            {currentView === RESULT_VIEW &&
                                <QuizResult
                                    onClick={() => {
                                        setCurrentView(FLIP_COIN_VIEW);
                                    }}
                                    coins={coins}
                                />
                            }
                            {currentView === LOTTERY_RESULT_VIEW && <LotteryResult/>}
                            {currentView === PRIZES_VIEW && <Prizes/>}
                            {currentView === TOPSCORE_VIEW && <TopScore/>}
                            <BottomMenu isQuizView={currentView !== START_VIEW} isAbsent={checkBottomMenu(currentView)}
                                        isFetchingUnfinishedTickets={isFetchingUnfinishedTickets && !!tokenData}/>
                        </motion.div>

                        {currentModal && <ErrorModal modalType={currentModal}/>}
                    </AnimatePresence>
                </>
            }

        </>
    );
}

export default App;
