export const nb = {
  translation: {
    topMenu: {
      closeButton: 'Lukk spill',
      demoBoxText: 'DEMO',
    },
    start: {
      heading: 'Vinn hver gang',
      playButton: 'SPILL',
      demoButton: 'SPILL DEMO',
    },
    purchase: {
      heading: 'Velg et nivå og\ndin innsats',
      stakeButtonTopPrize: 'Toppgevinst',
      purchaseDemoTicketButton: 'Få demo billett',
      purchaseTicketButton: 'Betal {{amount}}',
      paragraph: 'Vennligst merk at spillet ikke kan avbrytes',
      purchaseCompleteText: 'Betalingen lykkes',
    },
    summary: {
      heading: 'GRATULERER!',
      subHeading: 'Du vant {{amount}}',
      demoSubHeading: "Du vant i prøvespillet",
      playButton: 'Spill igjen',
      closeButton: 'Lukk spill',
    },
    bonusModal: {
      purchaseConfirmation: {
        firstParagraph1: 'Du vil ha',
        firstParagraph2: 'nådd',
        firstParagraph3: 'din bonus!',
        firstParagraph4: 'igjen til bonus!',
        bonusPayoutText: 'Bonus!',
        voucherText: '{{amount}} gratis spill!',
      },
      receipt: {
        firstParagraph1: 'Du har',
        firstParagraph2: 'nådd',
        firstParagraph3: 'din bonus!',
        firstParagraph4: 'igjen til bonus!',
        moneyPayout: '{{amount}} er satt inn på lommeboken din.',
        voucherPayout: 'Dine gratis spill har blitt betalt ut.',
        percentPayout: 'Din bonus har blitt betalt ut!',
      },
      purchaseView: {
        purchaseView: 'igjen til bonus!',
        bonusPayoutText: 'Bonus!',
        voucherText: '{{amount}} gratis spill!',
      },
    },
    gameInfo: {
      heading: 'Info',
      gameInfoHeading: 'Spillinfo',
      winningsplanHeading: 'Premieplan',
      gameInfoContentBold:
        'I en avsidesliggende del av Arktis er det uoppdagede skatter som venter på å bli funnet. Slå og bryt alle isblokkene du kan finne. I denne skattejakten kommer ingen hjem tomhendt.',
      gameInfoContent1:
        'Skjult i isblokkene er edelstener av varierende verdi. Alle edelstenene ender opp i skattekisten din.',
      gameInfoContent2:
        'Hvor mye klarer du å samle i de kalde utstrekningene av Arktis? Du vil ta med deg hele verdien av kisten hjem.',
      gameInfoContent3:
        'Den totale verdien av alle innsamlede edelstener vil være belønningen din.\nLykke til med jakten!',
    },
    prizeplan: {
      title: 'Premieplan',
      text: 'Lotteriet består av 1 000 000 lodd per innsats hver med totalt 1 000 000 vinnende billetter.',
      heading1: 'Antall',
      heading2: 'Premie',
      jackpot: 'Millionsjanse',
      returnToPlayer: 'Tilbake til spiller',
      payoutPercentage: '%',
      showGemValues: 'Vis edelsten verdier',
      million: 'Millionsjanse',
    },
    activeGame: {
      skipButton: 'hopp over',
    },
    onboarding: {
      skip: 'hopp over',
      continue: 'fortsett',
      step1: {
        heading: 'Slik spiller du',
        text1:
          'I en avsidesliggende del av Arktis er det uoppdagede skatter som venter på å bli funnet.',
        text2:
          'Slå og bryt alle isblokkene du kan finne. I denne skattejakten kommer ingen hjem tomhendt.',
      },
      step2: {
        heading: 'Vinn hver gang',
        text1:
          'Skjult i isblokkene er edelstener av varierende verdi. Alle edelstenene ender opp i skattekisten din.',
        text2:
          'Hvor mye klarer du å samle i de kalde utstrekningene av Arktis? Du vil ta med deg hele verdien av kisten hjem.',
      },
      step3: {
        heading: 'Edelstenverdier',
        text1:
          'Den totale verdien av alle innsamlede edelstener vil være belønningen din. Lykke til med jakten!',
      },
    },
    modal: {
      login: 'Logg inn',
      logout: 'Logg ut',
      demo: 'Spill demo',
      loginTitle: 'Du må logge inn',
      loginContent:
        'Du må logge inn for å kunne spille og vinne. Du kan spille demoen først hvis du vil!',
      walletTitle: 'Utilstrekkelige midler',
      walletContent: 'Saldoen din er for lav for din valgte innsats.',
      sessionTimeoutTitle: 'Spilløkten din har gått ut',
      sessionTimeoutContent: 'Logg på igjen for å fortsette å spille. Hvis du var midt i å spille et spill, kan spillet ditt spilles når du har logget på igjen.',
      sessionTimeoutButton: 'Avslutt',
      gameLimitsTitle: 'Du har nådd din spillgrense.',
      gameLimitsContent: 'Vent til det blir tilbakestilt.',
      unfinishedTitle: 'Gjenoppta spillet ditt?',
      unfinishedContent:
        'Du har en uferdig lottobillett. Vil du spille den nå?',
      generalErrorTitle: 'Noe gikk galt',
      generalErrorContent: 'Prøv igjen.',
      close: 'Lukk',
      resume: 'Spill',
      viewResult: 'Sjekk resultat',
      ok: 'Ok',
      showGemstoneValuesButtonText: 'Vis edelsten verdier',
    },
    gemstoneValues: {
      heading: 'Edelstenverdier',
    },
    amount: '{{amount}}',
    currencyFormat: 'NOK',
    currencyLocale: 'nb-NO',
    doNotSupportLandscape: 'Roter enheten for å spille',
    doNotSupportIE: 'Dette spillet støtter ikke nettleseren din.',
    doNotSupportIEConfirmButton: 'Ok',
    errors: {
      default: 'Noe gikk galt!',
      userBalance:
        'Kunne ikke hente saldo, last siden på nytt for å prøve igjen.',
      purchase: 'Et problem oppstod under kjøpet, prøv igjen.',
      demoPurchase: 'Noe gikk galt med demobilletten din, prøv igjen.',
      gameStop: 'Du har spillstopp aktivert.',
      limitNotSet: 'Du må sette dine spillgrenser før du kjøper billetter.',
      noAvailableTickets: 'Det er ingen tilgjengelige billetter å kjøpe.',
      getTicketError: 'Noe gikk galt når du samlet inn billetten din.',
      somethingWrongWithTicket: 'Ukjent feil med billetten din.',
      replayLoginError: 'Du må logge inn for å spille billetten din på nytt.',
    },
  },
};
