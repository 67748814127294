/* eslint-disable react/no-array-index-key */
import React, {useEffect, useState} from 'react';
import {ReactComponent as Arrow} from "../../../../assets/icons/top-score-white-caret-down.svg";
import clsx from 'clsx';
import {bool, func, shape} from 'prop-types';
import styles from './WinnerRow.module.scss';
import {formatSpaceBetweenDigits} from "../../../../utils/formatNumber";
import {useTranslation} from "react-i18next";
import {Winner} from "../../../../types/Winner";
import {getFormattedDate, getWeek} from "../../../../utils/dates";

interface WinnerRowProps {
    listItem: any;
    isListTypeWeek: boolean;
    onClick: (length: number, isOpen: boolean) => void;
}

function WinnerRow({listItem, isListTypeWeek, onClick}: WinnerRowProps) {
    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [firstItem, setFirstItem] = useState<Winner | null>(null);
    const [restItems, setRestItems] = useState<Winner[]>([]);

    useEffect(() => {
        if (listItem?.leaderboard.length > 0) {
            const [first, ...rest] = listItem.leaderboard;
            setFirstItem(first);
            setRestItems(rest);
        } else {
            setFirstItem(null);
            setRestItems([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <tr className={clsx(styles.row, {
                    [styles.hasRestItems]: restItems.length && !isListTypeWeek,
                })}
                onClick={() => {
                    if (restItems.length && !isListTypeWeek) {
                        onClick(restItems?.length, isOpen);
                        setIsOpen(!isOpen);
                    }
                }}
            >
                <td className={styles.date}>
                    {restItems.length && !isListTypeWeek ? (
                        <Arrow
                            className={clsx(styles.arrow, {
                                [styles.isOpen]: isOpen,
                            })}
                        />
                    ) : null}
                    {isListTypeWeek
                        ? t('topscore.quizWinners.weekNumber', {
                            weekNumber: getWeek(new Date(listItem?.endTime)),
                        })
                        : getFormattedDate(listItem?.endTime)}
                </td>
                <td className={clsx(styles.nickname, {
                    [styles.isFirstPlace]: firstItem?.rank === 1,
                })}>
                    {firstItem
                        ? `${firstItem.rank}. ${firstItem.nickname}`
                        : t('topscore.quizWinners.noWinner')}
                </td>
                <td className={clsx(styles.score, {
                    [styles.isFirstPlace]: firstItem?.rank === 1,
                })}>
                    {firstItem
                        ? formatSpaceBetweenDigits(firstItem?.score)
                        : '0'}
                </td>
            </tr>
            {restItems &&
                restItems.map((restItem, index) => (
                    <tr
                        key={index}
                        className={clsx(styles.row, {
                            [styles.isRest]: true,
                            [styles.isOpen]: isOpen,
                            [styles.isLastRowOpen]: index === restItems.length - 1 && isOpen,
                        })}
                    >
                        <td className={styles.date}/>
                        <td className={clsx(styles.nickname, {
                                [styles.isSecondPlace]: restItem.rank === 2,
                            })}>
                            {`${restItem.rank}. ${restItem.nickname}`}
                        </td>
                        <td className={clsx(styles.score, {
                            [styles.isSecondPlace]: restItem.rank === 2,
                        })}>
                            {formatSpaceBetweenDigits(restItem.score)}
                        </td>
                    </tr>
                ))}
        </>
    );
}

WinnerRow.propTypes = {
    listItem: shape({}),
    isListTypeWeek: bool,
    onClick: func.isRequired,
};

WinnerRow.defaultProps = {
    listItem: null,
    isListTypeWeek: false,
};

export default WinnerRow;
