import {MouseEventHandler, ReactNode} from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';

interface Props {
    children?: ReactNode;
    className?: string;
    onClick?: MouseEventHandler;
    isSecondary?: boolean;
    isTertiary?: boolean;
    isQuaternary?: boolean;
    isDisabled?: boolean;
    isModalCloseButton?: boolean;
    isQuizAnswerButton?: boolean;
    isBuyButton?: boolean;
    isPricePlan?: boolean;
    isGameInfo?: boolean;
    isActive?: boolean;
    isIncorrect? :boolean;
    isCorrect? :boolean;
}

function Button({
                    children,
                    className,
                    onClick,
                    isSecondary,
                    isTertiary,
                    isQuaternary,
                    isDisabled,
                    isModalCloseButton,
                    isQuizAnswerButton,
                    isBuyButton,
                    isPricePlan,
                    isGameInfo,
                    isActive,
                    isIncorrect,
                    isCorrect,
                }: Props): JSX.Element {
    const isPrimary: boolean =
        !isSecondary &&
        !isTertiary &&
        !isModalCloseButton &&
        !isBuyButton &&
        !isGameInfo &&
        !isQuaternary &&
        !isPricePlan &&
        !isQuizAnswerButton &&
        !isIncorrect &&
        !isCorrect;

    return (
        <button
            type="button"
            disabled={!!isDisabled}
            className={clsx(className, styles.wrapper, {
                [styles.primary]: isPrimary,
                [styles.secondary]: isSecondary,
                [styles.tertiary]: isTertiary,
                [styles.quaternary]: isQuaternary,
                [styles.modalCloseButton]: isModalCloseButton,
                [styles.buyButton]: isBuyButton,
                [styles.pricePlan]: isPricePlan,
                [styles.quizAnswer]: isQuizAnswerButton,
                [styles.gameInfo]: isGameInfo,
                [styles.active]: isActive,
                [styles.disabled]: isDisabled,
                [styles.incorrect]: isIncorrect,
                [styles.correct]: isCorrect,
            })}
            onClick={onClick}
        >
            {children && <div className={styles.childrenWrapper}>{children}</div>}
        </button>
    );
}

export default Button;
