import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {QuizContext} from "../../../contexts/QuizContext";
import {AppContext} from "../../../contexts/AppContext";
import apiClient from "../../../utils/apiClient";
import clsx from "clsx";
import {useMutation} from "react-query";
import CoinFlipAnimation from "../../elements/CoinFlipAnimation/CoinFlipAnimation";
import UserCoins from "./UserCoins/UserCoins";
import {COUNTDOWN_VIEW, ERROR, LOTTERY_RESULT_VIEW, WINNING_VIEW} from "../../../config/constants";
import Button from '../../elements/Button/Button';
import FlipAllCoins from "./FlipAllCoins/FlipAllCoins";
import {DemoContext} from "../../../contexts/DemoContext";
import SoundManager from "../../../utils/SoundManager";
import styles from './FlipCoin.module.scss';
import formatCurrency from "../../../utils/formatCurrency";

function FlipCoin() {
    const {t} = useTranslation();
    const {
        lotteryResult,
        nextQuestion,
        coinIsFlipped,
        currentTicket,
        setCurrentView,
        setQuestionNumber,
        coins,
        setCoins,
        quizIsSkipped,
        questionNumber,
    } = useContext(QuizContext);
    const {setCurrentModal, isReplay} = useContext(AppContext)
    const { isDemo, incrementQuestionIndex } = useContext(DemoContext);

    const [currentCoin] = useState( !!nextQuestion ?
        lotteryResult.arrayLayout[nextQuestion.orderNumber - 1] : lotteryResult.arrayLayout[6]
    );
    const [showGlow, setShowGlow] = useState(false);

    const isMountedRef = useRef(true);
    const timeOutRef = useRef<NodeJS.Timeout | number>(0);

    const getStatus = useMutation(
        () =>
            apiClient.get(`api/Quiz/Tickets/${currentTicket.correlationId}/status`, {}),
        {
            onSuccess: (response: any) => {
                if (response.questionsAnswered !== nextQuestion.questionsInTicket) {
                    setQuestionNumber(response.questionsAnswered + 1);
                } else {
                    setTimeout(() => {
                        if (currentTicket.tickets[0].isWinner) {
                            setCurrentView(WINNING_VIEW);
                        } else {
                            setCurrentView(LOTTERY_RESULT_VIEW);
                        }
                    }, 5000);
                }
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const onClickFlipCoin = (coinValue: any) => {
        const newCoin = {value: coinValue};
        setTimeout(() => {
            setCoins([...coins, newCoin]);
        }, 2000);
    };

    const onClickCoin = () => {
        setShowGlow(true);
        onClickFlipCoin(currentCoin);
        return currentCoin;
    };

    const onClickNextQuestion = () => {
        if (!getStatus.isSuccess && !isDemo) return;
        SoundManager.instance.playVO('button');
        setCurrentView(COUNTDOWN_VIEW);
    };

    useEffect(() => {
        if (isDemo) {
            if (nextQuestion.orderNumber !== nextQuestion.questionsInTicket) {
                incrementQuestionIndex();
                setQuestionNumber(questionNumber + 1);
            } else {
                timeOutRef.current = setTimeout(() => {
                    if (!timeOutRef.current) return;
                    if (lotteryResult.prizeValue > 0) {
                        setCurrentView(WINNING_VIEW);
                    } else {
                        setCurrentView(LOTTERY_RESULT_VIEW);
                    }
                }, 5000);
            }
        } else {
            if (nextQuestion) {
                getStatus.mutate();
            }
        }
        return () => {
            isMountedRef.current = false;
            clearTimeout(timeOutRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (quizIsSkipped || (isReplay)) {
            return (
                <FlipAllCoins/>
            );
    }

    return (
        <div className={styles.page}>
            <div className={styles.flipCoinWrapper}>

                <div className={styles.headingBox}>
                    <h2 className={styles.heading}>{t('quiz.flipCoin.heading').toUpperCase()}</h2>
                    <p className={styles.questionCounter}>{t('quiz.flipCoin.questionCounter', {
                        index: nextQuestion.orderNumber,
                        total: nextQuestion.questionsInTicket
                    })}</p>
                    <p className={styles.subHeading}>{t('quiz.flipCoin.subHeading')}</p>
                </div>

                <div className={styles.coinFlipBox}>
                    <p className={clsx(styles.coinFlipText, {
                        [styles.isFlipped]: coinIsFlipped,
                    })}>
                        {`${formatCurrency(currentCoin)}`}
                    </p>
                    <div className={clsx(styles.lightRays, {
                        [styles.showGlow]: showGlow,
                    })}></div>
                    <CoinFlipAnimation onClickCoin={onClickCoin}/>
                </div>

                <div className={styles.userCoinsBox}>
                    <UserCoins revealNewCoin={coinIsFlipped}/>
                </div>

                <div className={styles.bottomBlock}>
                    {!coinIsFlipped && (
                        <p className={styles.paragraph}>{t('quiz.flipCoin.comment')}</p>
                    )}

                    {nextQuestion.orderNumber !== nextQuestion.questionsInTicket &&
                        coinIsFlipped && (
                            <Button
                                isDisabled={!getStatus.isSuccess && !isDemo}
                                className={styles.nextButton}
                                onClick={onClickNextQuestion}
                            >
                                {t('quiz.flipCoin.nextQuestion')}
                            </Button>
                        )}
                </div>

            </div>
        </div>
    );
}

export default FlipCoin;