import React, { useRef, useEffect, useState } from 'react';
import { func, number, bool, string } from 'prop-types';
import gsap from 'gsap';
import clsx from 'clsx';
import {ReactComponent as TimerIcon} from '../../../../assets/icons/timer-icon.svg';
import SoundManager from "../../../../utils/SoundManager";
import styles from './Timer.module.scss';

export default function Timer({
  endOfTimer,
  time,
  hasAnswered,
  pauseTime,
  result,
}) {
  const totalTime = time;
  const timer = useRef();
  const isPausedRef = useRef();
  const [currentTime, setCurrentTime] = useState(totalTime);
  const [gsapAnimation, setGsapAnimation] = useState();

  useEffect(() => {
    if (currentTime === 4) {
      SoundManager.instance.playVO('timeout');
    }
    const percentLeft = ((totalTime - currentTime + 1) / totalTime) * 100;
    setGsapAnimation(
      gsap.to(`.${styles.time}`, {
        x: percentLeft < 0 ? '0%' : `-${percentLeft}%`,
        ease: 'linear',
        duration: percentLeft < 0 ? 0.2 : 1.1,
      })
    );
    if (currentTime <= 0) {
      endOfTimer();
      clearInterval(timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  const tick = () => {
    if (isPausedRef.current) return;
    setCurrentTime((oldVal) => oldVal - 1);
  };

  useEffect(() => {
    timer.current = setInterval(tick, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    if (gsapAnimation) {
      if (pauseTime) {
        isPausedRef.current = true;
        gsapAnimation.pause();
      } else {
        isPausedRef.current = false;
        gsapAnimation.play();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseTime]);

  useEffect(() => {
    if (hasAnswered) {
      gsapAnimation.pause();
      clearInterval(timer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnswered]);

  return (
    <div className={styles.timerBox}>
      <TimerIcon className={styles.icon} />
      <div
        className={clsx(styles.timerContainer, {
          [styles.isWrong]: result === 'WrongAnswer' || result === 'Expired',
          [styles.isCorrect]: result === 'CorrectAnswer',
        })}
      >
        <div
          className={clsx(styles.time, {
            [styles.isWrong]: result === 'WrongAnswer' || result === 'Expired',
            [styles.isCorrect]: result === 'CorrectAnswer',
          })}
        />
      </div>
    </div>
  );
}

Timer.propTypes = {
  endOfTimer: func.isRequired,
  hasAnswered: bool.isRequired,
  time: number,
  result: string,
  pauseTime: bool,
};

Timer.defaultProps = {
  time: 30,
  result: null,
  pauseTime: false,
};
