import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
import Providers from "./components/Providers/Providers";
import setInnerHeightVar from "./utils/innerHeightVariable";

setInnerHeightVar();

export default class SportsMaster {
    init(config) {
        const root = ReactDOM.createRoot(
            document.getElementById(config.elementId)
        );
        root.render(
            <React.StrictMode>
                <Providers>
                    <App initData={config}/>
                </Providers>
            </React.StrictMode>
        );
    }
}

window.SportsMaster = SportsMaster;