import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import WinnerRow from "./WinnerRow/WinnerRow";
import styles from './QuizWinners.module.scss';
import {Winner} from "../../../types/Winner";

interface QuizWinnersProps {
    winners: Winner[];
    isListTypeWeek: boolean;
}

const QuizWinners = ({winners, isListTypeWeek}: QuizWinnersProps) => {
    const {t} = useTranslation();

    const [height, setHeight] = useState(0);
    const tableHeaders = [
        t('topscore.quizWinners.tableHeaderDate'),
        t('topscore.quizWinners.tableHeaderWinner'),
        t('topscore.quizWinners.tableHeaderScore'),
    ];

    return (
                <table className={styles.table}>
                    <thead className={styles.thead}>
                    <tr className={styles.row}>
                        {tableHeaders &&
                            tableHeaders.map((tableHeader) => (
                                <th key={tableHeader} className={styles.tableHeader}>
                                    {tableHeader}
                                </th>
                            ))}
                    </tr>
                    </thead>
                    <tbody>
                    {winners?.map((item) => {
                        const dateNow = new Date();
                        if (new Date(item.endTime) > dateNow) return null;
                        return (
                            <WinnerRow
                                key={item.id}
                                listItem={item}
                                isListTypeWeek={isListTypeWeek}
                                onClick={(itemsCount, isItemOpen) => {
                                    if (!isItemOpen) {
                                        setHeight(height + (itemsCount * 34 + 20));
                                    } else {
                                        setHeight(height - (itemsCount * 34 + 20));
                                    }
                                }}
                            />
                        );
                    })}
                    </tbody>
                </table>
    );
};

export default QuizWinners;