import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from './UserCoins.module.scss';
import Coin from './Coin/Coin';
import {QuizContext} from "../../../../contexts/QuizContext";
import clsx from "clsx";

interface CoinType {
    value: any;
    isDuplicate: boolean;
    delay?: number;
}

function UserCoins({classname, revealNewCoin }: { classname?: string, revealNewCoin: boolean }) {
    const ref = useRef<HTMLDivElement | null>(null);

    const { lotteryResult, nextQuestion } = useContext(QuizContext);
    const activeSlotIndex = nextQuestion.orderNumber - 1;

    const [coins, setCoins] = useState<CoinType[]>([]);

    const findDuplicates = (arr: []) => {
        const set = new Set(arr);
        // eslint-disable-next-line array-callback-return
        return arr.filter((item) => {
            if (set.has(item)) {
                set.delete(item);
            } else {
                return item;
            }
        });
    };

    const isDuplicate = (value: any, list: any[]) => !!list.find((d) => d === value);

    const calculateDuplication = (value: any, list: any[]) => {
        if (
            revealNewCoin &&
            activeSlotIndex === lotteryResult.arrayLayout.length - 1
        ) {
            return value === lotteryResult.prizeValue;

        }
        if (revealNewCoin) {
            return isDuplicate(value, list);
        }
        return false;
    };

    const getCoins = (slotIndex: number) => {
        const { arrayLayout } = lotteryResult;
        const activeCoins = arrayLayout.slice(0, slotIndex);
        const duplicates = findDuplicates(activeCoins);
        const newCoins = [];
        for (let i = 0; i < arrayLayout.length; i++) {
            const isDuplicateCoin = calculateDuplication(arrayLayout[i], duplicates);
            // @ts-ignore
            const delay = isDuplicateCoin ? duplicates.indexOf(arrayLayout[i]) : 0;
            newCoins.push({
                value: arrayLayout[i],
                isDuplicate: isDuplicateCoin,
                delay: delay,
            });
        }

        return newCoins;
    };

    useEffect(() => {
        if (lotteryResult) {
            const newCoins = [...getCoins(activeSlotIndex)];
            setCoins(newCoins);
        }
        return () => {
            setCoins([]);
        };
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (revealNewCoin) {
            setCoins(getCoins(activeSlotIndex + 1));
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [revealNewCoin]);

    return (
        <div className={clsx (styles.coinBox, classname)} ref={ref}>
            {coins &&
                coins.length &&
                coins.map((data: CoinType, index: number) => (
                    <Coin
                        coinValue={data.value}
                        isDuplicate={data.isDuplicate}
                        isRevealed={activeSlotIndex > index}
                        revealNewCoin={revealNewCoin && activeSlotIndex === index}
                        className={styles.coinWrapper}
                        key={index}
                        animationDelay={data.delay || 0}
                    />
                ))}
        </div>
    );
}

export default UserCoins;
