import {useContext} from "react";
import {AnimatePresence, motion} from "framer-motion";
import Links from './helpers/Links'
import {ReactComponent as CloseMenuButtonIcon} from '../../assets/icons/close-menu-button-icon.svg';
import {AppContext} from "../../contexts/AppContext";
import Button from "../elements/Button/Button";
import styles from './Menu.module.scss'
import {DemoContext} from "../../contexts/DemoContext";
import {PURCHASE_VIEW} from "../../config/constants";
import {QuizContext} from "../../contexts/QuizContext";

interface Props {
    isOpen: boolean;
    cycleOpen: () => void;
}

export default function Menu({isOpen, cycleOpen}: Props) {
    const {tokenData, setUserPaymentSuccess, setIsReplay} = useContext(AppContext);
    const {isDemo, resetDemoBetweenRounds} = useContext(DemoContext);
    const {
        setCurrentTicket,
        setCoins,
        setActivatedTicket,
        setUserIsPlayingQuiz,
        setQuestionNumber,
        setQuizIsSkipped,
        setAccumulatedScore,
        setQuestionSummary,
        setLotteryResult,
        setNextQuestion,
        setNextCategory,
        setIncorrectAnsweredQuestions,
        setHelperRemainderDisplayed,
        setUnfinishedTicket,
        setTicketQuestion,
        setCoinIsFlipped

    } = useContext(QuizContext);

    const resetBetweenRounds = () => {
        setCurrentTicket(null);
        setCoins([]);
        setActivatedTicket(null);
        setUserPaymentSuccess(false);
        setUserIsPlayingQuiz(false);
        setQuestionNumber(1);
        setQuizIsSkipped(false);
        setAccumulatedScore(null);
        setQuestionSummary(null);
        setLotteryResult(null);
        setNextQuestion(null);
        setNextCategory(null);
        setIncorrectAnsweredQuestions(0);
        setHelperRemainderDisplayed(false);
        setUnfinishedTicket(null);
        setTicketQuestion(null);
        setIsReplay(false);
        setCoinIsFlipped(false);
    };

    const handleClick = () => {
        if (isDemo) {
            resetDemoBetweenRounds();
        } else {
            resetBetweenRounds();
        }
        cycleOpen();
    }

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.aside
                    className={styles.menuBox}
                    initial={{width: 0}}
                    animate={{width: 230,
                        transition: {duration: 0.25}
                }}
                    exit={{
                        width: 0,
                        transition: {delay: 0.2, duration: 0.2}
                    }}>

                    <motion.div
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {delay: 0.5}}}
                        exit={{opacity: 0}}
                    >
                        <Button
                            className={styles.closeButton}
                            isModalCloseButton
                            onClick={cycleOpen}
                        >
                            <CloseMenuButtonIcon/>
                        </Button>
                    </motion.div>
                        <Links isPlayVisible={!!tokenData?.access_token} handleClick={handleClick}/>
                </motion.aside>
            )}
        </AnimatePresence>

    )
}