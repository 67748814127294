import React, {useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import gsap from 'gsap';
import front from '../../../../../assets/coins/coin/coin-flip-front.png';
import styles from './Coin.module.scss';
import getWinningSymbol from "../../../../../utils/getWinningSymbol";
import formatCurrency from "../../../../../utils/formatCurrency";

interface CoinProps {
    coinValue: number;
    isRevealed: boolean;
    isDuplicate: boolean;
    revealNewCoin: boolean;
    className: string;
    animationDelay: number;
}

function Coin({
                  coinValue,
                  isRevealed,
                  isDuplicate,
                  revealNewCoin,
                  className,
                  animationDelay,
              }: CoinProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const q = useMemo(() => gsap.utils.selector(ref), [ref]);
    const [imageSrc, setImageSrc] = useState(front);
    const [showGlow, setShowGlow] = useState(false);

    useEffect(() => {
        if (isRevealed) {
            setImageSrc(getWinningSymbol(coinValue));
            gsap.to(q(`.${styles.coinValue}`), {
                opacity: 1,
                scale: 1,
                duration: 0.5,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (revealNewCoin) {
            gsap.to(q(`.${styles.coinImage}`), {
                opacity: 0,
                scale: 0,
                duration: 0.3,
                onComplete: () => {
                    setImageSrc(getWinningSymbol(coinValue));
                    gsap.to(q(`.${styles.coinImage}`), {
                        opacity: 1,
                        scale: 1,
                        duration: 0.4,
                        delay: 0.5,
                    });
                    gsap.to(q(`.${styles.coinValue}`), {
                        opacity: 1,
                        scale: 1,
                        duration: 0.4,
                        delay: 0.5,
                    });
                },
            });
        }
    }, [coinValue, q, revealNewCoin]);

    useEffect(() => {
        if ((isDuplicate && isRevealed) || (isDuplicate && revealNewCoin)) {
            setShowGlow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDuplicate]);

    return (
        <div className={clsx(className, styles.coin)} ref={ref}>
            {(isRevealed || revealNewCoin) &&
                <p className={styles.coinValue}>{formatCurrency(coinValue)}</p>
            }
            <img
                className={clsx(className, styles.coinImage, showGlow ? styles.coinGlow : '')}
                src={imageSrc}
                alt={'coin'}
                style={{animationDelay: `${animationDelay}s`}}
            />
        </div>
    );
}

export default Coin;
