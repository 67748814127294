import React, {
    Dispatch,
    SetStateAction, useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';
import {AppContext} from "../../contexts/AppContext";
import Button from "../elements/Button/Button";
import {
    COUNTDOWN_VIEW,
    DEMO,
    ERROR,
    FLIP_COIN_VIEW,
    LOGIN,
    ONBOARDING,
    RESULT_VIEW,
    SESSION_TIMEOUT,
    TOTAL_NUMBER_OF_QUESTIONS,
    UNFINISHED,
    WALLET
} from "../../config/constants";
import {QuizContext} from "../../contexts/QuizContext";
import {ReactComponent as CloseModalIcon} from '../../assets/icons/close-modal-button-icon.svg';
import styles from './ErrorModal.module.scss';
import {DemoContext} from "../../contexts/DemoContext";
import apiClient from "../../utils/apiClient";
import {useMutation} from "react-query";
import SoundManager from "../../utils/SoundManager";

type Props = {
    modalType: string;
};

type ModalContent = {
    title: string;
    textContent: string;
    buttonOneText: string;
    buttonOneFunction: () => void | null;
    isButtonOneDisabled?: boolean;
    buttonTwoText: string;
    buttonTwoFunction: () => void | null;
};

function Modal({modalType}: Props): JSX.Element {
    const {t} = useTranslation();
    const {
        setCurrentView,
        setQuestionNumber,
        setLotteryResult,
        setQuizIsSkipped,
        ticketStatus,
        setNextQuestion,
        ticketQuestion,
        unfinishedTicket,
        setCoins,
        ticketResult,
        setUserIsPlayingQuiz,
        setUnfinishedTicket,
    } = useContext(QuizContext);

    const {setCurrentModal, callback, currentError} = useContext(AppContext);
    const {isDemo, setIsDemo, demoQuizIsReady, demoTicketResult, resetDemoBetweenRounds} = useContext(DemoContext);

    const modalWrapperRef: React.Ref<HTMLDivElement> = useRef(null);
    const modalRef: React.Ref<HTMLDivElement> = useRef(null);
    const [modalContent, setModalContent]: [
            ModalContent | null | undefined,
        Dispatch<SetStateAction<ModalContent | null | undefined>>
    ] = useState();

    const closeModal: () => void = () => {
        setCurrentModal('');
    };

    const startDemo: () => void = () => {
        if (!isDemo) {
            setIsDemo(true);
        }
        closeModal();
        setUserIsPlayingQuiz(true);
        setCurrentView(COUNTDOWN_VIEW);
    };

    const onClickDemoPlay = () => {
        setIsDemo(true);
        setCurrentModal(DEMO);
    }

    const startGame: () => void = () => {
        console.log('status', ticketStatus)
        switch (ticketStatus) {
            case 'Skipped':
                setQuizIsSkipped(true);
                setNextQuestion({
                    orderNumber: 1,
                    questionsInTicket: TOTAL_NUMBER_OF_QUESTIONS,
                });
                setCurrentView(FLIP_COIN_VIEW);
                break;
            case 'Active':
                if (ticketQuestion && ticketQuestion <= TOTAL_NUMBER_OF_QUESTIONS) {
                    setCurrentView(COUNTDOWN_VIEW);
                } else {
                    setCurrentView(RESULT_VIEW);
                }
                break;
            default:
                setNextQuestion({
                    orderNumber: TOTAL_NUMBER_OF_QUESTIONS,
                    questionsInTicket: TOTAL_NUMBER_OF_QUESTIONS,
                });
                setCurrentView(FLIP_COIN_VIEW);
        }

        closeModal();
        setUnfinishedTicket(null);
        setUserIsPlayingQuiz(true);
    };

    const skipQuiz = useMutation(
        () =>
            apiClient.put(
                `api/Quiz/Tickets/${unfinishedTicket.correlationId}/status`,
                {
                    status: 'Skipped',
                }
            ),
        {
            onSuccess: () => {
                if (ticketQuestion !== 1) {
                    const newItems: { value: any; }[] = [];
                    ticketResult?.arrayLayout.forEach((value: any, index: number) => {
                        if (index < TOTAL_NUMBER_OF_QUESTIONS - 1) newItems.push({value});
                    });
                    setCoins(newItems);
                }
                setQuizIsSkipped(true);
                setNextQuestion({
                    orderNumber: ticketQuestion === 1 ? 1 : TOTAL_NUMBER_OF_QUESTIONS,
                    questionsInTicket: TOTAL_NUMBER_OF_QUESTIONS,
                });
                setQuestionNumber(ticketQuestion === 1 ? 1 : TOTAL_NUMBER_OF_QUESTIONS);
                setCurrentView(FLIP_COIN_VIEW);
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const skipGame: () => void = () => {
        skipQuiz.mutate();
        setUserIsPlayingQuiz(true);
        setUnfinishedTicket(null);
        closeModal();
    }

    const onViewOnboading = () => {
        setCurrentView(COUNTDOWN_VIEW);
        closeModal();
        localStorage.setItem('hasViewedTutorial', 'true');
    }

    const closeClient: () => void = () => {
        callback('close', '');
    };

    const setContent: (modalType: string) => ModalContent | null = (
            modalType: string
        ) => {
            let content: ModalContent | null = null;
            let errorMessage: string = t('modal.generalErrorTitle');

            if (currentError) {
                switch (currentError) {
                    case 'Unauthorized':
                        errorMessage = 'Unauthorized';
                        break;
                    case 'Error_CouldNotFindCompetitionForId':
                    case 'Error_Invalid_Competition_For_Ticket':
                        errorMessage = 'No active competition found.';
                        break;
                    case 'Error_CantAnswerThisQuestion':
                        errorMessage = 'Could not answer this question.';
                        break;
                    case 'Ticket_Is_Already_Finalized':
                        errorMessage = 'This ticket has already been finished.';
                        break;
                    case 'Error_TicketNotFound':
                    case 'Error_EntityIdMustBeGreaterThanZero':
                    case 'Error_WrongCompetitionId':
                    case 'Error_NoQuestionsFound':
                        errorMessage = 'Unknown error when fetching your ticket.';
                        break;
                    case 'Error_TicketNotActive':
                        errorMessage = 'This ticket is not active anymore.';
                        break;
                    case 'Purchase_Failed':
                    case 'Invalid_Order_Reference':
                    case 'UnSuccessful_Transaction':
                        errorMessage = 'Wager failed. Please try again.';
                        break;
                    default:
                        errorMessage = t('modal.generalErrorContent');
                }
            }

            switch (modalType) {
                case LOGIN:
                    content = {
                        title: t('modal.loginTitle'),
                        textContent: t('modal.loginContent'),
                        buttonOneText: t('modal.login'),
                        buttonOneFunction: (): void => {
                            closeModal();
                        },
                        buttonTwoText: t('modal.demo'),
                        buttonTwoFunction: () => onClickDemoPlay(),
                    };
                    break;

                case WALLET:
                    content = {
                        title: t('modal.walletTitle'),
                        textContent: t('modal.walletContent'),
                        buttonOneText: '',
                        buttonOneFunction: (): void => {
                            closeModal();
                        },
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case SESSION_TIMEOUT:
                    content = {
                        title: t('modal.sessionTimeoutTitle'),
                        textContent: t('modal.sessionTimeoutContent'),
                        buttonOneText: t('modal.sessionTimeoutButton'),
                        buttonOneFunction: (): void => {
                            closeClient();
                        },
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case UNFINISHED:
                    content = {
                        title: t('modal.unfinishedTitle'),
                        textContent: t('modal.unfinishedContent'),
                        buttonOneText: t('modal.resume'),
                        buttonOneFunction: () => startGame(),
                        buttonTwoText: t('modal.viewResult'),
                        buttonTwoFunction: () => skipGame(),
                    };
                    break;

                case ONBOARDING:
                    content = {
                        title: t('tutorial.heading'),
                        textContent: t('tutorial.secondParagraph'),
                        buttonOneText: t('start.playButton'),
                        buttonOneFunction: () => onViewOnboading(),
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case DEMO:
                    content = {
                        title: t('tutorial.heading'),
                        textContent: t('tutorial.secondParagraph'),
                        buttonOneText: t('start.playButton'),
                        buttonOneFunction: () => startDemo(),
                        isButtonOneDisabled: !demoQuizIsReady,
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;

                case ERROR:
                    content = {
                        title: errorMessage,
                        textContent: '',
                        buttonOneText: t('modal.close'),
                        buttonOneFunction: () => closeModal(),
                        buttonTwoText: '',
                        buttonTwoFunction: () => null,
                    };
                    break;
            }
            return content;
        }
    ;

    useEffect(() => {
        setModalContent(setContent(modalType));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType, currentError]);

    useEffect(() => {
        if (isDemo && demoQuizIsReady) {
            setQuestionNumber(1);
            setLotteryResult(demoTicketResult);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [demoQuizIsReady]);

    return (
        <div
            className={clsx(styles.modalWrapper)}
            ref={modalWrapperRef}
        >
            <div
                className={clsx(styles.flexWrapper)}
            >
                <div
                    key="modalInnerWrapper"
                    className={clsx(styles.innerWrapper)}
                    ref={modalRef}
                >
                    {modalType !== UNFINISHED && (
                        <Button
                            isModalCloseButton
                            className={styles.closeCross}
                            onClick={() => {
                                SoundManager.instance.playVO('button');
                                closeModal();
                                if (modalType === DEMO) {
                                    resetDemoBetweenRounds();
                                }
                            }}
                        >
                            <CloseModalIcon/>
                        </Button>
                    )}
                    <p className={styles.modalTitle}>{modalContent?.title}</p>
                    <p className={styles.modalText}>{modalContent?.textContent}</p>
                    {(modalContent?.buttonOneText || modalContent?.buttonTwoText) &&
                        <div className={styles.buttonsWrapper}>

                            {modalContent?.buttonOneText && (
                                <Button
                                    isDisabled={!demoQuizIsReady && modalType === DEMO}
                                    onClick={(): void => {
                                        SoundManager.instance.playVO('button');
                                        modalContent?.buttonOneFunction();
                                    }}
                                >
                                    {modalContent?.buttonOneText}
                                </Button>
                            )}

                            {modalContent?.buttonTwoText && (
                                <Button
                                    isSecondary
                                    isDisabled={modalType === UNFINISHED && ticketStatus === 'Skipped'}
                                    onClick={(): void => {
                                        SoundManager.instance.playVO('button');
                                        modalContent?.buttonTwoFunction();
                                    }}
                                >
                                    {modalContent?.buttonTwoText}
                                </Button>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

Modal.defaultProps = {
    modalType: '',
};

export default Modal;
