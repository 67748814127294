import React, {useContext, useEffect, useRef} from 'react';
import {useQuery} from "react-query";
import styles from './Countdown.module.scss';
import gsap from 'gsap';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../contexts/AppContext";
import {QuizContext} from "../../../contexts/QuizContext";
import apiClient from "../../../utils/apiClient";
import CountdownAnim from "./CountdownAnim/CountdownAnim";
import {ERROR, QUESTION_VIEW} from "../../../config/constants";
import {DemoContext} from "../../../contexts/DemoContext";

export default function Countdown() {
    const {setCurrentModal} = useContext(AppContext);
    const {
        currentTicket,
        nextCategory,
        setNextCategory,
        questionNumber,
        setCurrentView,
        setCoinIsFlipped,
    } = useContext(QuizContext);
    const {isDemo, getQuestionCountdown} = useContext(DemoContext);
    const {t} = useTranslation();

    const timeline = useRef(gsap.timeline());
    const ref = useRef<HTMLDivElement>(null);

    const {isSuccess} = useQuery(
        'category',
        () =>
            apiClient.get(
                `api/Quiz/Tickets/${currentTicket.correlationId}/questions/${questionNumber}/countdown`
                , {}),
        {
            enabled: !!currentTicket && !isDemo,
            onSuccess: (response: { category: string }) => {
                if (response.category) {
                    setNextCategory(response);
                }
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const fadeIn = () => {
        timeline.current = gsap.timeline();
        const tl = timeline.current;
        const {header, subHeader, categoryHeader, category} = styles;

        if (tl) {
            // Fade in all elements
            const d = 0.3;
            const s = 0.2;
            tl.to(`.${header}`, {
                opacity: 1,
                duration: d,
            });
            tl.to(`.${subHeader}`,
                {
                    opacity: 1,
                    duration: d,
                },
                `-=${s}`
            );
            tl.to(`.${categoryHeader}`,
                {
                    opacity: 1,
                    duration: d,
                },
                `-=${s}`
            );
            tl.to(`.${category}`,
                {
                    opacity: 1,
                    duration: d,
                },
                `-=${s}`
            );
        }
    };

    const countdownComplete = () => {
        setCoinIsFlipped(false);
        setCurrentView(QUESTION_VIEW);
    };

    useEffect(() => {
        const fetchNextCategory = async () => {
            const category = await getQuestionCountdown();
            setNextCategory(category);
        };
        if (isDemo) {
            fetchNextCategory();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            nextCategory &&
            nextCategory.orderNumber === questionNumber &&
            (isSuccess || isDemo)
        ) {
            fadeIn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextCategory, isSuccess]);

    if (
        !nextCategory ||
        nextCategory.orderNumber !== questionNumber
    ) {
        return <div className={styles.filler}/>;
    }

    return (
        <div className={styles.page} ref={ref}>
            <div className={styles.countdownWrapper}>
                <div className={styles.headerBox}>
                    <h2 className={styles.header}>{t('quiz.countdown.header').toUpperCase()}</h2>
                    <span className={styles.subHeader}>
          {t('quiz.countdown.subHeader', {
              index: questionNumber,
              totalQuestions: 7,
          })}
        </span>
                </div>
                <div className={styles.categoryBox}>
        <span className={styles.categoryHeader}>
          {t('quiz.countdown.category').toUpperCase()}
        </span>
                    {nextCategory && nextCategory.category && (
                        <span className={styles.category}>
            {nextCategory.category.displayName.toUpperCase()}
          </span>
                    )}
                </div>
                <div className={styles.countdownBox}>
            <span className={styles.countdownText}>
          {t('quiz.countdown.countdownText')}
        </span>
                    <CountdownAnim onAnimComplete={countdownComplete}/>
                </div>
            </div>
        </div>
    );
}