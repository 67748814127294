export const sv = {
  translation: {
    topMenu: {
      closeButton: 'Stäng spel',
      demoBoxText: 'DEMO',
    },
    start: {
      heading: 'Vinn varje gång',
      playButton: 'Spela',
      demoButton: 'Spela demo',
    },
    purchase: {
      heading: 'Välj bana och\ndin insats',
      stakeButtonTopPrize: 'Toppvinst',
      purchaseDemoTicketButton: 'Prova demo',
      purchaseTicketButton: 'Betala {{amount}}',
      paragraph: 'Notera att spelet inte går att avbryta',
      purchaseCompleteText: 'Betalning lyckades',
    },
    summary: {
      heading: 'GRATTIS!',
      subHeading: 'Du vann {{amount}}',
      demoSubHeading: "Vinst på demospel",
      playButton: 'Spela igen',
      closeButton: 'Stäng spel',
    },
    bonusModal: {
      purchaseConfirmation: {
        firstParagraph1: 'Du kommer att ha',
        firstParagraph2: 'uppnått',
        firstParagraph3: 'din bonus!',
        firstParagraph4: 'kvar till bonus!',
        bonusPayoutText: 'Bonus!',
        voucherText: '{{amount}} gratisspel!',
      },
      receipt: {
        firstParagraph1: 'Du har',
        firstParagraph2: 'uppnått',
        firstParagraph3: 'din bonus!',
        firstParagraph4: 'kvar till bonus!',
        moneyPayout: '{{amount}} sätts in i din spelplånbok.',
        voucherPayout: 'Dina gratisspel har betalats ut.',
        percentPayout: 'Din bonus har betalats ut!',
      },
      purchaseView: {
        purchaseView: 'kvar till bonus!',
        bonusPayoutText: 'Bonus!',
        voucherText: '{{amount}} gratisspel!',
      },
    },
    gameInfo: {
      heading: 'Info',
      gameInfoHeading: 'Så funkar det',
      winningsplanHeading: 'Vinstplan',
      gameInfoContentBold:
        'In a remote part of the Arctic there are undiscovered treasures waiting to be found. Hit and break all the ice blocks you can find. In this treasure hunt no one comes home empty handed.',
      gameInfoContent1:
        'Hidden in the blocks of ice are gemstones of varying value. All the gems end up in your treasure chest.',
      gameInfoContent2:
        'How much are you able to collect in the cold expanses of the Arctic? You will bring home the full value of the chest.',
      gameInfoContent3:
        'The total value of all the collected gemstones will be your reward.\nHappy hunting!',
    },
    prizeplan: {
      title: 'Vinstplan',
      text: 'Lotteriet kontrolleras av Spelinspektionen. Lotteriet omfattar 1 000 000 lotter per insatsnivå med totalt 1 000 000 vinster.',
      heading1: 'Antal',
      heading2: 'Vinstbelopp',
      jackpot: 'Chans på miljonen',
      returnToPlayer: 'Återbetalning',
      payoutPercentage: '%',
      showGemValues: 'Visa juvelvärden',
      million: 'Chans på miljonen',
    },
    activeGame: {
      skipButton: 'skip',
    },
    onboarding: {
      skip: 'hoppa över',
      continue: 'fortsätt',
      step1: {
        heading: 'Så spelar du',
        text1:
          'In a remote part of Arctic there are undiscovered treasures waiting to be found.',
        text2:
          'Hit and break all the ice blocks you can find. In this treasure hunt no one comes home empty handed.',
      },
      step2: {
        heading: 'Vinst varje gång',
        text1:
          'Hidden in the blocks of ice are gemstones of varying value. All the gems end up in your treasure chest.',
        text2:
          'How much are you able to collect in the cold expanses of the Arctic? You will bring home the full value of the chest.',
      },
      step3: {
        heading: 'Juvelernas värde',
        text1:
          'The total value of all the collected gemstones will be your reward. Happy hunting!',
      },
    },
    modal: {
      login: 'Logga in',
      logout: 'Logga ut',
      demo: 'Spela demo',
      loginTitle: 'Du måste logga in',
      loginContent:
        'Du måste logga in för att kunna spela. Du kan alltid prova ett demospel!',
      walletTitle: 'För lite pengar',
      walletContent: 'Du har för lite pengar för din insatsnivå.',
      sessionTimeoutTitle: 'Din spelsession har gått ut',
      sessionTimeoutContent: 'Logga in igen för att fortsätta spela. Om du var mitt uppe i ett spel kan ditt spel spelas när du har loggat in igen.',
      sessionTimeoutButton: 'Avsluta',
      gameLimitsTitle: 'Du har nått en spelgräns.',
      gameLimitsContent:
        'Du måste vänta tills spelgränsen nollställts innan du kan spela igen.',
      unfinishedTitle: 'Återuppta ditt spel?',
      unfinishedContent: 'Du har ett ofärdigt spel. Vill du spela det nu?',
      generalErrorTitle: 'Något gick fel',
      generalErrorContent: 'Försök igen.',
      close: 'Stäng',
      resume: 'Spela',
      viewResult: 'Kontrollera resultat',
      ok: 'Ok',
      showGemstoneValuesButtonText: 'Visa juvelvärden',
    },
    gemstoneValues: {
      heading: 'Juvelvärden',
    },
    amount: '{{amount}}',
    currencyFormat: 'SEK',
    currencyLocale: 'sv-SE',
    doNotSupportLandscape: 'Rotera enheten för att spela',
    doNotSupportIE: 'Klienten stödjer inte denna webbläsare.',
    doNotSupportIEConfirmButton: 'Ok',
    errors: {
      default: 'Något gick fel!',
      userBalance:
        'Vi lyckades inte uppdatera ditt saldo, ladda om sidan för korrekt värde.',
      purchase: 'Ett fel uppstod vid köpet, prova igen.',
      demoPurchase:
        'Något gick fel när vi skulle hämta demolotten, prova igen.',
      gameStop: 'Du har spelstopp aktiverat.',
      limitNotSet: 'Du måste sätta spelgränser innan du kan köpa lotter.',
      noAvailableTickets: 'Det finns inga lotter att köpa.',
      getTicketError: 'Något gick fel när din lott skulle hämtas.',
      somethingWrongWithTicket: 'Någt har gått fel med din lott.',
      replayLoginError:
        'Du måste logga in för att kunna se återuppspela din lott.',
    },
  },
};
