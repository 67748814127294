import text from 'i18next';


export default function formatCurrency(amount: number) {
  const currencySettings = {
    style: 'currency',
    currency: text.t('currencyFormat'),
    minimumFractionDigits: 0,
  };

  return new Intl.NumberFormat(
    text.t('currencyLocale'),
    currencySettings
  ).format(amount);
}
