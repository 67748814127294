import React, {useContext, useEffect, useRef} from 'react';
import {useQuery} from 'react-query';
import gsap from "gsap";
import clsx from 'clsx';
import {QuizContext} from "../../../contexts/QuizContext";
import {AppContext} from "../../../contexts/AppContext";
import {ERROR, PURCHASE_VIEW, TOPSCORE_VIEW} from "../../../config/constants";
import apiClient from "../../../utils/apiClient";
import {useTranslation} from "react-i18next";
import styles from './LotteryResult.module.scss';
import {formatSpaceBetweenDigits} from "../../../utils/formatNumber";
import Button from '../../elements/Button/Button';
import {Rank} from "../../../types/Highscore";
import {DemoContext} from "../../../contexts/DemoContext";
import {getUserAvatar} from "../../../utils/getAvatar";
import ReplayLotteryResult from "../ReplayLotteryResult/ReplayLotteryResult";
import formatCurrency from "../../../utils/formatCurrency";

function LotteryResult() {
    const {t} = useTranslation();
    const {
        currentTicket,
        lotteryResult,
        questionsSummary,
        setQuestionSummary,
        quizIsSkipped,
        highscore,
        setHighscore,
        setCurrentView,
        setCurrentTicket,
        setCoins,
        setUserIsPlayingQuiz,
        setLotteryResult,
        setQuestionNumber,
        setQuizIsSkipped,
        setAccumulatedScore,
        setNextQuestion,
        setIncorrectAnsweredQuestions,
        setHelperRemainderDisplayed,
        setActivatedTicket,
        setUnfinishedTicket,
        setTicketQuestion,
        setCoinIsFlipped,
        setNextCategory,
    } = useContext(QuizContext);
    const {
        setCurrentModal,
        refetchCustomerBalance,
        setUserPaymentSuccess,
        userData,
        setIsReplay,
        isReplay,
    } =
        useContext(AppContext);
    const {
        isDemo,
        demoQuizResultSummary,
        resetDemoBetweenRounds,
        funModebalance,
        setFunModeBalance
    } = useContext(DemoContext);

    const lotteryResultRef = useRef(null);
    const gameResultWrapperRef = useRef(null);
    const playAgainButtonBoxRef = useRef(null);

    const {data: ticketFinalizeData} = useQuery(
        ['ticketFinalize', currentTicket && currentTicket.correlationId],
        () => apiClient.put(`api/XSame/${currentTicket.correlationId}`, {}),
        {
            enabled: !isDemo && !!currentTicket,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            onSuccess: () => {
                if (lotteryResult.prizeValue > 0) {
                    refetchCustomerBalance();
                }
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    useQuery(
        ['ticketSummary', currentTicket && currentTicket.correlationId],
        () =>
            apiClient.get(`api/Quiz/Tickets/${currentTicket.correlationId}/summary`, {}),
        {
            enabled: !quizIsSkipped && !isDemo && currentTicket !== null,
            refetchOnWindowFocus: false,
            onSuccess: (response: any) => {
                setHighscore(response);
            },
            onError: () => {
                setHighscore(null);
                setCurrentModal(ERROR);
            },
        }
    );

    useQuery(
        ['quizSummary', currentTicket && currentTicket.correlationId],
        () =>
            apiClient.get(`api/Quiz/Tickets/${currentTicket.correlationId}/results`, {}),
        {
            enabled: questionsSummary === null && !quizIsSkipped && !isDemo,
            refetchOnWindowFocus: false,
            onSuccess: (response) => {
                setQuestionSummary(response);
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const resetBetweenRounds = () => {
        setCurrentTicket(null);
        setCoins([]);
        setCurrentView(PURCHASE_VIEW);
        setActivatedTicket(null);
        setUserPaymentSuccess(false);
        setUserIsPlayingQuiz(false);
        setQuestionNumber(1);
        setQuizIsSkipped(false);
        setAccumulatedScore(null);
        setQuestionSummary(null);
        setLotteryResult(null);
        setNextQuestion(null);
        setNextCategory(null);
        setIncorrectAnsweredQuestions(0);
        setHelperRemainderDisplayed(false);
        setUnfinishedTicket(null);
        setTicketQuestion(null);
        setIsReplay(false);
        setCoinIsFlipped(false);
    };

    const goToTopScore = () => {
        resetBetweenRounds();
        setCurrentView(TOPSCORE_VIEW);
    }

    const replayQuiz = () => {
        resetBetweenRounds();
        if (isDemo) {
            resetDemoBetweenRounds();
        }
    };

    useEffect(() => {
        sessionStorage.setItem('userHasPlayed', 'true');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isDemo) {
            setQuestionSummary(demoQuizResultSummary);
            if (funModebalance !== null) {
                setFunModeBalance(funModebalance + lotteryResult.prizeValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (questionsSummary && questionsSummary.questionScores.length > 0) {
            if (lotteryResultRef.current && gameResultWrapperRef.current && playAgainButtonBoxRef.current) {
                const tl = gsap.timeline();

                tl.fromTo(
                    lotteryResultRef.current,
                    {opacity: 0},
                    {opacity: 1, duration: 0.5}
                )
                    .fromTo(
                        gameResultWrapperRef.current,
                        {opacity: 0},
                        {opacity: 1, duration: 0.5}
                    )
                    .fromTo(
                        playAgainButtonBoxRef.current,
                        {opacity: 0},
                        {opacity: 1, duration: 0.5}
                    );
            }
        }
    }, [questionsSummary]);

    if (
        (highscore === null || questionsSummary === null) &&
        !quizIsSkipped &&
        !isDemo
    ) {
        return <div className={styles.filler}/>;
    }

    if (isReplay) {
        return <ReplayLotteryResult
            lotteryResult={lotteryResult}
            replayQuiz={replayQuiz}
            ticketFinalizeData={ticketFinalizeData}
        />;
    }

    return (
        <div className={styles.page}>

            {questionsSummary && questionsSummary.questionScores.length > 0 && (
                <div className={styles.lotteryResult} ref={lotteryResultRef}>

                    <h3 className={styles.header}>
                        {t('quiz.lotteryResult.header')}
                    </h3>

                    {highscore &&
                        <p className={styles.personalBest}>
                            {t('quiz.lotteryResult.personalBest')}
                            <span>{` ${formatSpaceBetweenDigits(
                                highscore.highscore
                            )}`}</span>
                        </p>
                    }

                    {highscore && (
                        <div className={styles.highScoreWrapper}>

                            <div className={styles.points}>
                                <img
                                    className={styles.avatar}
                                    src={getUserAvatar(userData ? userData.avatarId : 0)}
                                    alt="av"
                                />
                                <p>{t('quiz.lotteryResult.myPoints')}</p>
                                <span>{`${formatSpaceBetweenDigits(
                                    questionsSummary.totalScore
                                )}`}</span>
                            </div>

                            {highscore?.ranks &&
                                <div className={styles.placement}>
                                    <p>
                                        {t('quiz.lotteryResult.todaysPlacement')}
                                        <span>{` ${
                                            highscore.ranks.find(
                                                (val: Rank) => val.name.toLowerCase() !== 'vecka'
                                            )?.rank
                                        }`}</span>
                                    </p>
                                    <p>
                                        {t('quiz.lotteryResult.weeksPlacement')}
                                        <span>{` ${
                                            highscore.ranks.find(
                                                (val: Rank) => val.name.toLowerCase() === 'vecka'
                                            )?.rank
                                        }`}</span>
                                    </p>
                                </div>
                            }

                            <div className={styles.buttonBox}>
                                <Button className={styles.topscoreButton} onClick={goToTopScore}>
                                    {t('quiz.lotteryResult.topscoreButtonText')}
                                </Button>
                            </div>

                        </div>
                    )}

                </div>
            )}

            {!isDemo &&
                <div className={clsx(styles.gameResultWrapper, {
                    [styles.noQuestionsSummary]: !questionsSummary || questionsSummary.questionScores.length === 0,
                })} ref={gameResultWrapperRef}>
                    {lotteryResult.prizeValue === 0 ? (
                        <div className={styles.result}>
                            <p className={styles.noPrize}>{t('quiz.lotteryResult.noPrize')}</p>
                        </div>
                    ) : (
                        <div className={clsx(styles.result, styles.isWinning)}>

                            <h3 className={styles.prizeHeader}>
                                {t('quiz.lotteryResult.lotteryHeader')}
                            </h3>
                            <h1 className={styles.prizeValue}>{formatCurrency(lotteryResult.prizeValue)}</h1>

                        </div>
                    )}
                </div>
            }

            {isDemo &&
                <div className={styles.gameResultWrapper} ref={gameResultWrapperRef}>
                    <div className={styles.result}>
                        {
                            lotteryResult.prizeValue === 0 ?
                                <h1 className={styles.demoHeader}>{t('quiz.lotteryResult.demoGameLost')}</h1> :
                                <h1 className={styles.demoHeader}>{t('quiz.lotteryResult.demoGameWon')}</h1>
                        }
                    </div>
                </div>
            }

            {!isDemo &&
                <div className={clsx(styles.playAgainButtonBox,
                    {[styles.noPrize]: lotteryResult.prizeValue === 0})}
                     ref={playAgainButtonBoxRef}>
                    <p className={styles.playAgainText}>{t('quiz.lotteryResult.playAgainText')}</p>

                    <Button
                        isSecondary
                        onClick={replayQuiz}
                        isDisabled={!ticketFinalizeData}
                    >
                        {t('quiz.lotteryResult.playAgain')}
                    </Button>

                </div>
            }

            {isDemo &&
                <div className={styles.playAgainButtonBox} ref={playAgainButtonBoxRef}>
                    <p className={styles.playAgainText}>{t('quiz.lotteryResult.playAgainText')}</p>

                    <Button
                        isSecondary
                        onClick={replayQuiz}
                    >
                        {t('quiz.lotteryResult.playAgain')}
                    </Button>

                </div>
            }

        </div>
    );
}

export default LotteryResult;
