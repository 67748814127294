import React, {useState, useEffect, useRef, useContext, useMemo} from 'react';
import clsx from 'clsx';
import gsap from 'gsap';
import {useQuery} from 'react-query'
import {QuizContext} from "../../../contexts/QuizContext";
import {AppContext} from "../../../contexts/AppContext";
import apiClient from "../../../utils/apiClient";
import {ERROR} from "../../../config/constants";
import {useTranslation} from "react-i18next";
import {formatSpaceBetweenDigits} from "../../../utils/formatNumber";
import Button from '../../elements/Button/Button';
import {ReactComponent as QuizResultHeaderIcon} from "../../../assets/icons/quiz-result-header-icon.svg";
import {ReactComponent as QuizResultButtonIcon} from "../../../assets/icons/quiz-result-button-icon.svg";
import UserCoins from "../FlipCoin/UserCoins/UserCoins";
import {DemoContext} from "../../../contexts/DemoContext";
import styles from './QuizResult.module.scss';
import SoundManager from "../../../utils/SoundManager";

interface QuizResultProps {
    onClick: () => void;
    coins: any[];
}

interface QuestionScore {
    isCorrect: boolean;
    roundExpired: boolean;
    score: number;
    helperType?: 'FiftyFifty' | 'MoreTime' | 'NewQuestion';
}

function QuizResult({onClick}: QuizResultProps) {
    const {t} = useTranslation();
    const {currentTicket, questionsSummary, setQuestionSummary} =
        useContext(QuizContext);
    const {setCurrentModal} = useContext(AppContext);
    const { isDemo, demoQuizResultSummary } = useContext(DemoContext);

    const staggerIndex = useRef<number>(0);
    const sumScore = useRef<{ value: number }>({value: 0});
    const ref = useRef<HTMLDivElement | null>(null);
    const q = useMemo(() => gsap.utils.selector(ref), [ref]);

    const [total, setTotal] = useState<string>('0');
    const [animateCoins, setAnimateCoins] = useState(false);
    const [animateButton, setAnimateButton] = useState(false);

    useQuery(
        'quizSummary',
        () =>
            apiClient.get(`api/Quiz/Tickets/${currentTicket.correlationId}/results`, {}),
        {
            enabled: !!currentTicket,
            onSuccess: (response) => {
                setQuestionSummary(response);
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    useQuery(
        'quizFinalize',
        () =>
            apiClient.put(`api/Quiz/Tickets/${currentTicket.correlationId}/status`, {
                status: 'Finalized',
            }),
        {
            enabled: !!currentTicket,
            refetchOnWindowFocus: false,
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const animateScore = (addValue: number, index: number) => {
        gsap.to(sumScore.current, {
            value: sumScore.current.value + addValue,
            duration: 0.2,
            ease: 'power2.out',
            onUpdate: () => {
                let scoreString;
                if (
                    index === questionsSummary.questionScores.length - 1 &&
                    questionsSummary
                ) {
                    scoreString = `${Math.round(questionsSummary.totalScore)
                        .toLocaleString('en-US')
                        .replace(',', ' ')} p`;
                } else {
                    scoreString = `${Math.round(sumScore.current.value)
                        .toLocaleString('en-US')
                        .replace(',', ' ')} p`;
                }
                setTotal(scoreString);
            },
        });
    };

    useEffect(() => {
        if (isDemo) {
            setQuestionSummary(demoQuizResultSummary);
        }

        if (questionsSummary) {
            staggerIndex.current = 0;
            sumScore.current = {
                value: 0,
            };

            gsap.set(q(`.${styles.questionSummary}`), {
                opacity: 0,
                y: -20,
            });
            gsap.to(q(`.${styles.questionSummary}`), {
                opacity: 1,
                y: 0,
                ease: 'power2.out',
                duration: 0.5,
                delay: 0.5,
                onComplete: () => {
                    setAnimateCoins(true);
                },
                onStart: () => {
                    SoundManager.instance.playVO('quiz-result');
                },
                stagger: {
                    each: 0.1,
                    onStart: () => {
                        animateScore(
                            questionsSummary.questionScores[staggerIndex.current].score,
                            staggerIndex.current
                        );
                        staggerIndex.current += 1;
                    },
                },
            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionsSummary]);

    useEffect(() => {
        if (animateCoins) {
            gsap.to(q(`.${styles.userCoins}`), {
                opacity: 1,
                scale: 1,
                ease: 'back.out(1.7)',
                duration: 0.5,
                onComplete: () => {
                    setAnimateButton(true);
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animateCoins]);

    useEffect(() => {
        if (animateButton) {
            gsap.to(q(`.${styles.button}`), {
                opacity: 1,
                ease: 'power2.out',
                duration: 0.1,
            });
        }
        return () => {
            gsap.globalTimeline.clear();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animateButton]);

    if (!questionsSummary) {
        return <div className={styles.filler}/>;
    }

    return (
        <div className={styles.page}>
            <div className={styles.quizResult} ref={ref}>

                <p className={styles.header}>
                    <QuizResultHeaderIcon className={styles.headerIcon}/>
                    {t('quiz.quizResult.header')}
                </p>

                <div className={styles.scoreheader}>
                    <span className={styles.totalScoreLabel}>
                        {t('quiz.quizResult.scoreHeader')}
                    </span>
                    <span className={styles.totalScore}>{total}</span>
                </div>

                <div className={styles.questionSummaryBox}>
                    {questionsSummary.questionScores.map((item: QuestionScore, i: number) => (
                        <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            className={clsx(styles.questionSummary, {
                                [styles.isCorrect]: item.isCorrect,
                                [styles.isIncorrect]: !item.isCorrect,
                                [styles.isTimeoutCorrect]: item.roundExpired && item.isCorrect,
                                [styles.isTimeoutInCorrect]: item.roundExpired && !item.isCorrect,
                            })}
                        >
                            <span className={styles.numQuestion}>
                                {t('quiz.quizResult.questionNumber', {
                                    index: i + 1,
                                })}
                            </span>

                            <div className={styles.questionResult}>
                                {item.isCorrect
                                    ? t('quiz.quizResult.correctLabel')
                                    : t('quiz.quizResult.inCorrectLabel')}
                                <p className={styles.answer}>{t('quiz.quizResult.answer')}</p>
                            </div>
                            <span className={styles.questionScore}>
                                {item.roundExpired
                                    ? t('quiz.quizResult.timeoutLabel')
                                    : `${item.score > 0 ? '+' : ''} ${formatSpaceBetweenDigits(
                                        item.score
                                    )}`}
                            </span>
                        </div>
                    ))}
                </div>

                <UserCoins
                    classname={styles.userCoins}
                    revealNewCoin={false}
                />

                <Button className={styles.button} isSecondary onClick={onClick}>
                    <QuizResultButtonIcon className={styles.buttonIcon}/>
                    <span className={styles.buttonText}>
                        {t('quiz.quizResult.buttonText')}
                    </span>
                </Button>

            </div>

        </div>
    );
}

export default QuizResult;