import { Howl } from 'howler';

interface SoundMap {
  [key: string]: Howl;
}

export default class SoundManager {
  private static _instance: SoundManager | null = null;
  private isMuted: boolean = false;
  private voSprites: SoundMap = {};
  public currentSound: string | null = null;

  constructor() {
    if (SoundManager._instance) {
      return SoundManager._instance;
    }

    const soundNames = [
        'countdown', 'timeout', 'coin-flip',
      'correct-answer', 'wrong-answer', 'button',
        'menu-button', 'helper', 'quiz-music',
        'menu-music', 'quiz-result', 'winning',
    ];

    soundNames.forEach((soundName) => {
      let volume = 0.5;
      if (soundName === 'menu-music') {
        volume = 0.6;
      } else if (soundName === 'quiz-music') {
        volume = 0.3;
      }

      this.voSprites[soundName] = new Howl({
        src: [`/sounds/${soundName}.mp3`],
        volume: volume,
        loop: soundName === 'menu-music' || soundName === 'quiz-music',
      });
    });

    return this;
  }

  public static get instance(): SoundManager {
    if (!SoundManager._instance) {
      SoundManager._instance = new SoundManager();
    }

    return SoundManager._instance;
  }

  public playVO(id: string) {
    const sound = this.voSprites[id];
    if (sound && !this.isMuted) {
      sound.play();
      this.currentSound = id;
    }
  }

  public stopVO() {
    Object.values(this.voSprites).forEach((sound) => sound.stop());
  }

  public muteApp(value: boolean) {
    Object.values(this.voSprites).forEach((sound) => {
      if (value) {
        sound.mute(true);
      } else {
        sound.mute(false);
      }
    });
    this.isMuted = value;
  }
}