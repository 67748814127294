import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import gsap from "gsap";
import clsx from "clsx";
import {useQuery} from "react-query";
import apiClient from "../../utils/apiClient";
import {ERROR} from "../../config/constants";
import PrizePlans from "./PrizePlans/PrizePlans";
import {AppContext} from "../../contexts/AppContext";
import styles from './Prizes.module.scss';

//images
import signedShirt from '../../assets/prizes/signed-shirt.png';
import football from '../../assets/prizes/football.png';
import tickets from '../../assets/prizes/tickets.png';
import firstPlace from '../../assets/prizes/first-place.png';
import secondPlace from '../../assets/prizes/second-place.png';
import thirdPlace from '../../assets/prizes/third-place.png';

interface PrizeLevel {
    amount: number;
    value: number;
}

const Prizes = () => {
    const {t} = useTranslation();
    const {setCurrentModal} = useContext(AppContext)

    const [activeList, setActiveList] = useState('prizes');
    const [prizePlans, setPrizePlans] = useState({ returnToPlayer: 0, batchSize: 0, prizeLevels: [] as PrizeLevel[]});

    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);

    const navButtons = [
        {id: 'prizes', label: t('prizes.prizesLabel')},
        {id: 'prizeplans', label: t('prizes.prizeplansLabel')},
    ];

    useQuery(
        'prizePlans',
        () =>
            apiClient.get(`api/Game/188/prizePlans`, {}),
        {
            onSuccess: (response: any) => {
                setPrizePlans({
                    returnToPlayer: response.rtp,
                    batchSize: response.games[0].batchSize,
                    prizeLevels: response.games[0].prizePlans
                });
            },
            onError: () => {
                setCurrentModal(ERROR);
            }
        }
    );

    useEffect(() => {
        gsap.fromTo(firstRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay: 0.3});
        gsap.fromTo(secondRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay: 0.7});
        gsap.fromTo(thirdRef.current, {autoAlpha: 0}, {autoAlpha: 1, delay: 1.1});
    }, []);

    return (
        <div className={styles.page}>

            <div className={styles.buttonBox}>
                {navButtons.map(({id, label}) => (
                    <button
                        key={label}
                        type="button"
                        className={clsx(styles.navButton, {
                            [styles.isActive]: activeList === id,
                            [styles.isPrizesButtonActive]: activeList === 'prizes',
                            [styles.isPrizeplansButtonActive]: activeList === 'prizeplans',
                        })}
                        onClick={() => {
                            setActiveList(id);
                        }}
                    >
                        {label}
                    </button>
                ))}
            </div>

            {activeList === 'prizes' &&
                <div className={styles.prizes}>

                    <img className={styles.firstImage} src={signedShirt} alt={'shirt'}/>
                    <div className={styles.firstPlace} ref={firstRef}>
                        <div className={styles.firstPlaceLabel}>
                            <img src={firstPlace} alt="1"/> <span>{t('prizes.firstPlace')}</span>
                        </div>
                        <p>{t('prizes.prizeValue')}</p>
                        <span>{t('prizes.firstPrize')}</span>
                        <p>{t('prizes.firstPrizeDesc')}</p>
                    </div>

                    <div className={styles.secondPlace} ref={secondRef}>
                        <div className={styles.secondPlaceLabel}>
                            <img src={secondPlace} alt="2"/> <span>{t('prizes.secondPlace')}</span>
                        </div>
                        <p>{t('prizes.prizeValue')}</p>
                        <span>{t('prizes.secondPrize')}</span>
                        <p>{t('prizes.secondPrizeDesc')}</p>
                    </div>
                    <img className={styles.secondImage} src={tickets} alt={'tickets'}/>

                    <img className={styles.thirdImage} src={football} alt={'football'}/>
                    <div className={styles.thirdPlace} ref={thirdRef}>
                        <div className={styles.thirdPlaceLabel}>
                            <img src={thirdPlace} alt="3"/> <span>{t('prizes.thirdPlace')}</span>
                        </div>
                        <p>{t('prizes.prizeValue')}</p>
                        <span>{t('prizes.thirdPrize')}</span>
                        <p>{t('prizes.thirdPrizeDesc')}</p>
                    </div>

                </div>
            }

            {activeList === 'prizeplans' &&
                <>
                <PrizePlans
                    prizePlans={prizePlans}
                    tableHeaders={[
                        {tableHeader: 'Symbol'},
                        {tableHeader: '# of prizes'},
                        {tableHeader: 'Prizes'},
                    ]}
                />
                    <div className={styles.bottomBlock}>
                        <p className={styles.info}>
                            The lottery consists of {prizePlans.batchSize} tickets.
                            Return to player {prizePlans.returnToPlayer}%.
                        </p>
                    </div>
                </>
            }
        </div>
    );
};

export default Prizes;