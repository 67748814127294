import React, {useContext, useEffect, useState} from 'react';
import {QuizContext} from "../../../contexts/QuizContext";
import {useTranslation} from "react-i18next";
import styles from "./Purchase.module.scss";
import Button from "../../elements/Button/Button";
import clsx from "clsx";
import {AppContext} from "../../../contexts/AppContext";
import {Stake} from "../../../types/Stakes";
import {useMutation, useQueries, useQuery} from "react-query";
import apiClient from "../../../utils/apiClient";
import BottomMenu from "../../BottomMenu/BottomMenu";
import {
    COUNTDOWN_VIEW,
    ERROR,
    ONBOARDING,
    START_VIEW, WALLET,
} from "../../../config/constants";
import adjustOrderOfCoins from "../../../utils/adjustOrderOfCoins";
import formatCurrency from "../../../utils/formatCurrency";
import SoundManager from "../../../utils/SoundManager";

interface TicketData {
    correlationId: string;
    url: string;
    verb: string;
}

function Purchase() {
    const {
        stakes,
        tokenData,
        setCurrentModal,
        setUserPaymentSuccess,
        userPaymentSuccess,
        callback,
        refetchCustomerBalance,
    } = useContext(AppContext)
    const {
        setCurrentView,
        currentTicket,
        setCurrentTicket,
        setQuestionNumber,
        setLotteryResult,
        setTicketResult,
        ticketResult,
        setUserIsPlayingQuiz,
        setActivatedTicket,
        activatedTicket,
    } = useContext(QuizContext);
    const {t} = useTranslation();

    const [gameId, setGameId] = useState(null);
    const [collectionId, setCollectionId] = useState(null);
    const [selectedStake, setSelectedStake] = useState(stakes[0]);
    const [disableTicketButtons, setDisableTicketButtons] = useState(false);

    const {data: dataCompetitions} = useQuery(
        'competitions',
        () => apiClient.get('api/Quiz/Competitions', {}),
        {
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const getTicket = useMutation((postdata: { correlationId: string; competitionId: any; }) =>
        apiClient.post(`api/Quiz/Tickets/${postdata.correlationId}/${collectionId}`, {})
    );

    const activateTicket = async (ticketData: TicketData) => {
        try {
            const res = await getTicket.mutateAsync({
                correlationId: ticketData.correlationId,
                competitionId: gameId,
            });
            if (res) {
                setCurrentTicket(ticketData);
            }
        } catch (err: any) {
            console.error('Error in activating ticket: ', err);
            setCurrentModal(ERROR);
        }
    };

    const purchase = useMutation(
        (putData: {}) => apiClient.put('api/Game', putData),
        {
            retry: false,
            onSuccess: (response: any) => {
                setUserPaymentSuccess(true);
                activateTicket(response[0]);
                if (tokenData) {
                    refetchCustomerBalance();
                }
            },
            onError: (error: any) => {
                console.log('error: ', error.data);
                if(error.data === 'Not_Enough_Funds_To_Pay') {
                    setCurrentModal(WALLET)
                } else {
                    setCurrentModal(ERROR);
                }
            },
        }
    );

    useQueries([
        {
            queryKey: ['ticket', currentTicket ? currentTicket.correlationId : ''],
            queryFn: () =>
                apiClient.get(
                    `api/Game/GetTicket?combinationId=${currentTicket.correlationId}`, {}),
            enabled: currentTicket !== null,
            onSuccess: (response: any) => {
                console.log('query was successful', response);
                setActivatedTicket(response);
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        },
        {
            queryKey: [
                'lotteryResult',
                currentTicket ? currentTicket.correlationId : '',
            ],
            // @ts-ignore
            queryFn: () => apiClient.get(`${activatedTicket.tickets[0].url}`, {}),
            enabled: activatedTicket !== null,
            onSuccess: (response: any) => {
                const data = {
                    ...response,
                    arrayLayout: adjustOrderOfCoins(response.layout, response.prizeValue),
                };
                setTicketResult(data);
            },
            onError: () => {
                setCurrentModal(ERROR);
            },
        },
    ]);

    const onClickBuyButton: (stake: Stake) => void = (stake: Stake) => {
        SoundManager.instance.playVO('button');
        setSelectedStake(stake);
        if (!tokenData) {
            setCurrentView(START_VIEW);
        } else {
            setDisableTicketButtons(true);

            purchase.mutate({
                gameId: gameId,
                channel: '',
                additionalData: '',
            });
            if (callback)
                callback('wager', {
                    wager: stake.cost,
                    amount: 1,
                    gameCollectionId: gameId,
                });
        }
    };

    useEffect(() => {
        const dataCompetitionsArray = dataCompetitions as any;
        if (dataCompetitionsArray && dataCompetitionsArray.length > 0) {
            const weeklyCompetitions = dataCompetitionsArray.filter((competition: { type: string; }) => competition.type === 'Weekly');
            if (weeklyCompetitions.length > 0) {
                setGameId(weeklyCompetitions[0].gameCollectionId);
                setCollectionId(weeklyCompetitions[0].id);
            } else {
                setGameId(dataCompetitionsArray[0].gameCollectionId);
                setCollectionId(dataCompetitionsArray[0].id);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCompetitions]);

    useEffect(() => {
        if (activatedTicket && !activatedTicket.tickets[0].finalized && ticketResult) {
            setQuestionNumber(1);
            setCurrentTicket(activatedTicket);
            setLotteryResult(ticketResult);

            const hasViewedTutorial = localStorage.getItem('hasViewedTutorial');
            if (hasViewedTutorial === 'true') {
                setUserIsPlayingQuiz(true);
                setCurrentView(COUNTDOWN_VIEW);
            } else {
                setCurrentModal(ONBOARDING);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activatedTicket, ticketResult]);

    return (
        <div className={styles.page}>

            <div className={styles.purchaseBox}>
                <div className={styles.headingBox}>
                    <h2 className={styles.heading}>{t('quiz.purchase.heading').toUpperCase()}</h2>
                    <p className={styles.subHeading}>{t('quiz.purchase.topPrizeSubHeading')}</p>
                    <p className={styles.subHeadingAmount}>{t('quiz.purchase.topPrizeAmount')}</p>
                </div>

                <div className={styles.pricePlansBox}>

                    {stakes && stakes.map((stake) => {
                        return (
                            <Button
                                isPricePlan
                                className={clsx({[styles.isSelected]: selectedStake.gameId === stake.gameId})}
                                key={stake.gameId}
                                onClick={() => setSelectedStake(stake)}
                            >
                                <p className={styles.pricePlanAmount}>{formatCurrency(stake.cost)}</p>
                            </Button>
                        );
                    })}
                </div>

                {userPaymentSuccess &&
                    <div className={styles.purchaseSuccessBox}>
                        <p className={styles.purchaseSuccessText}>{t('quiz.purchase.purchaseCompleteText')}</p>
                    </div>
                }

            </div>
            <BottomMenu isQuizView isDisabled={disableTicketButtons} isAbsent={false}
                        onClick={() => onClickBuyButton(selectedStake)}/>
        </div>
    );
}

export default Purchase;