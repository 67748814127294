export function orderArrayByDateDescending(array: any[]) {
    return array.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());
}

export const calculateNumberOfDays = (timestamp1: string | number | Date, timestamp2: string | number | Date) => {
    const timeDifference =
        new Date(timestamp1).getTime() - new Date(timestamp2).getTime();
    return timeDifference / (1000 * 3600 * 24);
};

export function getWeek(date: Date) {
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 4 - (date.getDay() || 7));

    const oneJan = new Date(date.getFullYear(), 0, 1);
    const millisecondsInDay = 86400000; // 24 * 60 * 60 * 1000
    const dayOfYear = Math.floor((date.getTime() - oneJan.getTime()) / millisecondsInDay);
    return Math.ceil((dayOfYear + 1) / 7);
}

export function getFormattedDate(date: Date) {
    const d = new Date(date);
    return d.toLocaleDateString('sv-SE');
}