import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Timer from "./Timer/Timer";
import Button from "../../elements/Button/Button";
import apiClient from "../../../utils/apiClient";
import {useMutation, useQuery} from "react-query";
import {QuizContext} from "../../../contexts/QuizContext";
import {COUNTDOWN_VIEW, ERROR, FLIP_COIN_VIEW, RESULT_VIEW, TOTAL_NUMBER_OF_QUESTIONS} from "../../../config/constants";
import {AppContext} from "../../../contexts/AppContext";
import clsx from "clsx";
import HelperButton from "../../elements/HelperButton/HelperButton";
import styles from './Question.module.scss';
import {DemoContext} from "../../../contexts/DemoContext";
import SoundManager from "../../../utils/SoundManager";

interface ButtonData {
    guid: string;
    text: string;
    isCorrect: boolean;
    isIncorrect: boolean;
    isSelected: boolean;
    isDisabled: boolean;
}

interface AnswerData {
    noAnswerReason?: string;
    customerQuestionId?: string;
    answerGuid?: string;
}

interface QuestionResult {
    result?: string;
    userWasCorrect?: boolean;
    correctAnswer?: string;
    score?: number;
}

const TIME_HELPER = 'MoreTime';
const FIFTY_HELPER = 'FiftyFifty';
const NEW_QUESTION = 'NewQuestion';

function Question() {
    const {t} = useTranslation();
    const {
        currentTicket,
        questionNumber,
        setQuestionNumber,
        nextQuestion,
        setNextQuestion,
        setCurrentView,
        setQuestionScore,
        setHelperRemainderDisplayed,
    } = useContext(QuizContext);
    const {setCurrentModal} = useContext(AppContext);
    const {isDemo, getQuestion, postAnswer} = useContext(DemoContext);

    const [currentRoundHelper, setCurrentRoundHelper] = useState<string | null>(null);
    const [hasAnswered, setHasAnswered] = useState(false);
    const [questionResult, setQuestionResult] = useState<QuestionResult | null>(null);
    const [pauseTime, setPauseTime] = useState(false);
    const [additionalTime, setAdditionalTime] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);
    const [buttons, setButtons] = useState<ButtonData[]>([]);

    const questionRef = useRef(null);

    useQuery(
        'question',
        () =>
            apiClient.get(
                `api/Quiz/Tickets/${currentTicket.correlationId}/questions/${questionNumber}`, {}),
        {
            enabled: !isDemo && currentTicket !== null,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: (response: any) => {
                questionRef.current = response.customerQuestionId;
                setNextQuestion(response);
            },
            onError: (error: any) => {
                if (error.data === 'ERR_NO_TIME_LEFT') {
                    sendTimeoutAnswer();
                }
                setCurrentModal(ERROR);
                goToNextView();
            },
        }
    );

    const answer = useMutation((postData: {}) =>
        apiClient.post(
            `api/Quiz/Tickets/${currentTicket.correlationId}/questions/${questionNumber}/answer`,
            postData
        )
    );

    const useHelperFetch = useMutation((helper: string) =>
        apiClient.put(
            `api/Quiz/Tickets/${currentTicket.correlationId}/questions/${questionNumber}/helper/${helper}`,
            {}
        )
    );

    const goToNextView = () => {
        if (!nextQuestion) {
            setNextQuestion({
                orderNumber: questionNumber,
                questionsInTicket: TOTAL_NUMBER_OF_QUESTIONS,
            });
        }
        if (
            questionNumber === nextQuestion?.questionsInTicket ||
            questionNumber === TOTAL_NUMBER_OF_QUESTIONS
        ) {
            setCurrentView(RESULT_VIEW);
        } else {
            setCurrentView(FLIP_COIN_VIEW);
        }
    };

    const sendAnswer = async (guid: any) => {
        try {
            const data: AnswerData = {};
            if (!guid) {
                data.noAnswerReason = 'timeout';
            } else {
                data.customerQuestionId = nextQuestion.customerQuestionId;
                data.answerGuid = guid;
            }

            let res: QuestionResult;
            if (!isDemo) {
                // @ts-ignore
                res = await answer.mutateAsync(data);
            } else {
                res = await postAnswer(data);
            }

            if (res) {
                if (res.result === 'Expired' && guid) {
                    res.userWasCorrect = res.correctAnswer === guid;
                }
                setQuestionResult(res);

                setTimeout(() => {
                    goToNextView();
                }, 3000);

            }
        } catch (err) {
            console.error(err);
            setCurrentModal('Något gick fel när vi skulle hämta rätt svar!');
            goToNextView();
        }
    };

    const sendTimeoutAnswer = async () => {
        const data = {
            noAnswerReason: 'timeout',
        };
        const res = await answer.mutateAsync(data);
        if (res) {
            setQuestionNumber(questionNumber + 1);
            setCurrentView(COUNTDOWN_VIEW);
        }
    };

    const onTimeout = () => {
        setIsTimeout(true);
        sendAnswer('');
    }

    const onButtonClick = (guid: string) => {
        const newButtons = buttons.map((item) => {
            if (item.guid === guid) {
                return {
                    ...item,
                    isSelected: true,
                };
            }
            return item;
        });
        SoundManager.instance.playVO('button');
        setButtons(newButtons);
        if (!isTimeout) {
            sendAnswer(guid);
        }
    };

    const updateButtonsOnFiftyHelper = (resAlternatives: any[]) =>
        buttons.map((item) => {
            return {
                ...item,
                isDisabled: resAlternatives.every(
                    (resAlternative: { guid: string; }) => resAlternative.guid !== item.guid
                ),
            };
        });

    const onClickHelper = async (name: string) => {
        if (isTimeout) return;
        if (name === TIME_HELPER || name === NEW_QUESTION) {
            setPauseTime(true);
        }
        SoundManager.instance.playVO('helper');
        setHelperRemainderDisplayed(true);
        setCurrentRoundHelper(name);
        try {
            const res: any = await useHelperFetch.mutateAsync(name);
            if (res) {
                switch (name) {
                    case TIME_HELPER:
                        setAdditionalTime(true);
                        setPauseTime(false);
                        break;
                    case NEW_QUESTION:
                        setCurrentView(COUNTDOWN_VIEW);
                        break;
                    case FIFTY_HELPER:
                        setButtons(updateButtonsOnFiftyHelper(res.alternatives));
                        break;
                    default:
                }
            }
        } catch (err) {
            setPauseTime(false);
            console.error('Error when trying to use helper: ', err);
            setCurrentModal(ERROR);
        }
    };

    const fiftyFiftyHelpers =
        nextQuestion &&
        nextQuestion.helpers &&
        nextQuestion.helpers.find((h: any) => h.helperType === FIFTY_HELPER);

    const timeHelpers =
        nextQuestion &&
        nextQuestion.helpers &&
        nextQuestion.helpers.find((h: any) => h.helperType === TIME_HELPER);

    const newQuestionHelpers =
        nextQuestion &&
        nextQuestion.helpers &&
        nextQuestion.helpers.find((h: any) => h.helperType === NEW_QUESTION);

    useEffect(() => {
        const fetchNextQuestion = async () => {
            const question = await getQuestion();
            setNextQuestion(question);
        };
        if (isDemo) {
            fetchNextQuestion();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (questionResult) {
            setQuestionScore(questionResult.score !== undefined ? questionResult.score : null);
            const {correctAnswer} = questionResult;
            const newButtons = buttons.map((item) => {
                const selectedIsInCorrect =
                    item.isSelected && correctAnswer !== item.guid;
                return {
                    ...item,
                    isIncorrect: selectedIsInCorrect,
                    isSelected: false,
                    isCorrect: correctAnswer === item.guid,
                };
            });

            if (!isTimeout) {
                if (
                    questionResult.result === 'CorrectAnswer' ||
                    questionResult.userWasCorrect === true
                ) {
                    SoundManager.instance.playVO('correct-answer');
                } else {
                    SoundManager.instance.playVO('wrong-answer');
                }
            }

            setButtons(newButtons);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionResult]);

    useEffect(() => {
        if (
            nextQuestion &&
            nextQuestion.orderNumber === questionNumber &&
            nextQuestion.question
        ) {
            setButtons(nextQuestion.question.alternatives);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextQuestion]);

    if (
        !nextQuestion ||
        (!isDemo && questionRef.current !== nextQuestion.customerQuestionId) ||
        nextQuestion.orderNumber !== questionNumber
    ) {
        return <div className={styles.filler}/>;
    }

    return (
        <div className={styles.page}>
            <Timer
                key={additionalTime ? 'true' : 'false'}
                time={nextQuestion.timeLimitSeconds}
                hasAnswered={hasAnswered}
                pauseTime={pauseTime}
                endOfTimer={() => {
                    onTimeout();
                }}
                result={questionResult ? questionResult.result : null}
            />

            <div className={clsx(styles.questionContainer, {
                [styles.isImageQuestion]: nextQuestion.question.imageData,
            })}>
                {(!questionResult || questionResult.result !== 'CorrectAnswer') && (
                    <>
                        <span className={clsx(styles.questionCounter, {
              [styles.isImageQuestion]: nextQuestion.question.imageData,
          })}>
                            {t('quiz.question.questionCounter', {
                index: questionNumber,
                total: nextQuestion.questionsInTicket,
            })}
                        </span>
                        <span className={styles.questionText}>
                    {nextQuestion.question.text}
                </span>
                        {nextQuestion.question.imageData &&
                            <div className={clsx(styles.imageWrapper, {
                                [styles.isImageQuestion]: nextQuestion.question.imageData,
                            })}>
                                <img
                                    className={styles.image}
                                    src={nextQuestion.question.imageData}
                                    alt=""
                                />
                            </div>
                        }
                    </>
                )}

                {questionResult && questionResult.result === 'CorrectAnswer' && (
                    <span className={styles.resultText}>
                        {questionResult.score}
                    </span>
                )}
            </div>

            <div className={clsx(styles.buttonWrapper, {
                [styles.isImageQuestion]: nextQuestion.question.imageData,
            })}>
                {buttons &&
                    buttons.map((btn, index) => (
                        <Button
                            key={btn.guid || index}
                            className={styles.button}
                            isQuizAnswerButton
                            isIncorrect={questionResult !== null && btn.isIncorrect}
                            isCorrect={questionResult !== null && btn.isCorrect}
                            isActive={btn.isSelected}
                            isDisabled={btn.isDisabled}
                            onClick={() => {
                                if (hasAnswered) return;
                                setHasAnswered(true);
                                onButtonClick(btn.guid);
                            }}
                        >
                            {btn.text}
                        </Button>
                    ))}
            </div>

            {nextQuestion.helpers && !questionResult && (
                <div className={styles.helpers}>
                    <HelperButton
                        isHelperExtraTime
                        amountHelpers={timeHelpers.amount - timeHelpers.amountUnseen}
                        onClick={() => onClickHelper(TIME_HELPER)}
                        isActive={currentRoundHelper === TIME_HELPER}
                        disabled={
                            nextQuestion.helperUsedOnTicket ||
                            !nextQuestion.question.usableHelperTypes.includes(
                                TIME_HELPER
                            ) || currentRoundHelper !== null
                        }
                    />
                    <HelperButton
                        isHelperFiftyFifty
                        amountHelpers={fiftyFiftyHelpers.amount - fiftyFiftyHelpers.amountUnseen}
                        onClick={() => onClickHelper(FIFTY_HELPER)}
                        isActive={currentRoundHelper === FIFTY_HELPER}
                        disabled={
                            nextQuestion.helperUsedOnTicket ||
                            !nextQuestion.question.usableHelperTypes.includes(
                                FIFTY_HELPER
                            ) || currentRoundHelper !== null
                        }
                    />
                    <HelperButton
                        isHelperNewQuestion
                        amountHelpers={newQuestionHelpers.amount - newQuestionHelpers.amountUnseen}
                        onClick={() => onClickHelper(NEW_QUESTION)}
                        isActive={currentRoundHelper === NEW_QUESTION}
                        disabled={
                            nextQuestion.helperUsedOnTicket ||
                            !nextQuestion.question.usableHelperTypes.includes(
                                NEW_QUESTION
                            ) || currentRoundHelper !== null
                        }
                    />
                </div>
            )}

        </div>
    );
}

export default Question;