import React, {useContext, useEffect, useState} from 'react';
import styles from './Start.module.scss';
import {useTranslation} from "react-i18next";
import {useQueries, useQuery} from "react-query";
import apiClient from "../../utils/apiClient";
import {ERROR, TOTAL_NUMBER_OF_QUESTIONS, UNFINISHED} from "../../config/constants";
import adjustOrderOfCoins from "../../utils/adjustOrderOfCoins";
import {AppContext} from "../../contexts/AppContext";
import {QuizContext} from "../../contexts/QuizContext";
import {DemoContext} from "../../contexts/DemoContext";

function Start({resetBetweenRounds, setIsFetchingUnfinishedTickets}: any) {
    const {t} = useTranslation();

    const {
        tokenData,
        setCurrentModal,
        setCurrentError,
    } = useContext(AppContext)
    const {
        setCurrentTicket,
        setQuestionNumber,
        setLotteryResult,
        unfinishedTicket,
        setUnfinishedTicket,
        setCoins,
        setTicketResult,
        ticketResult,
        ticketQuestion,
        setTicketQuestion,
        setTicketStatus,
    } = useContext(QuizContext);
    const {resetDemoBetweenRounds} = useContext(DemoContext);

    const [gameId, setGameId] = useState(null);

    const {data: dataCompetitions} = useQuery(
        'competitions',
        () => apiClient.get('api/Quiz/Competitions', {}),
        {
            enabled: !!tokenData?.access_token,
            onError: (error: any) => {
                setCurrentError(error.data);
                setCurrentModal(ERROR);
            },
        }
    );

    const {data: dataUnfinishedTickets}: any = useQuery(
        'unfinishedTickets',
        () =>
            apiClient.post('api/Game/GetUnfinishedTickets', {
                gameId: gameId,
                channel: '',
                additionalData: '',
            }),
        {
            refetchOnWindowFocus: false,
            enabled: gameId !== null && !!tokenData?.access_token,
            onError: (error: any) => {
                setCurrentError(error.data);
                setCurrentModal(ERROR);
            },
        }
    );

    useQueries([
        {
            queryKey: ['status', unfinishedTicket ? unfinishedTicket.correlationId : ''],
            enabled: unfinishedTicket !== null && dataUnfinishedTickets.length > 0,
            queryFn: () =>
                apiClient.get(`api/Quiz/Tickets/${unfinishedTicket?.correlationId}/status`, {}),
            onSuccess: (response: any) => {
                if (
                    response.status === 'Active'
                ) {
                    setCurrentModal(UNFINISHED);
                    setTicketStatus(response.status);
                    if (response.questionsAnswered < TOTAL_NUMBER_OF_QUESTIONS && response.status === 'Active') {
                        setTicketQuestion(response.questionsAnswered + 1);
                    } else {
                        setTicketQuestion(TOTAL_NUMBER_OF_QUESTIONS);
                    }
                } else {
                    setTicketQuestion(0);
                }
            },
            onError: (error: any) => {
                switch (error.data) {
                    case 'Error_TicketNotFound': {
                        setTicketQuestion(TOTAL_NUMBER_OF_QUESTIONS);
                        setTicketStatus('Skipped');
                        break;
                    }
                    default: {
                        setCurrentError(error.data);
                        setCurrentModal(ERROR);
                    }
                }
            },
        },
        {
            queryKey: ['lotteryResult', unfinishedTicket ? unfinishedTicket.correlationId : ''],
            enabled: unfinishedTicket !== null && ticketQuestion !== 0,
            queryFn: () => apiClient.get(`${unfinishedTicket.tickets[0].url}`, {}),
            onSuccess: (response: any) => {
                const data = {
                    ...response,
                    arrayLayout: adjustOrderOfCoins(response.layout, response.prizeValue),
                };
                const newItems: React.SetStateAction<{ value: any; }[]> = [];
                data.arrayLayout.forEach((value: any, i: number) => {
                    if (ticketQuestion && i < ticketQuestion - 1) newItems.push({value});
                });
                setCoins(newItems);
                setTicketResult(data);
            },
            onError: (error: any) => {
                setCurrentError(error.data);
                setCurrentModal(ERROR);
            },
        },
    ]);

    useEffect(() => {
        setIsFetchingUnfinishedTickets(true);
        const dataCompetitionsArray = dataCompetitions as any;
        if (dataCompetitionsArray && dataCompetitionsArray.length > 0) {
            const weeklyCompetitions = dataCompetitionsArray.filter((competition: { type: string; }) => competition.type === 'Weekly');
            if (weeklyCompetitions.length > 0) {
                setGameId(weeklyCompetitions[0].gameCollectionId);
            } else {
                setGameId(dataCompetitionsArray[0].gameCollectionId);
            }
        }

        if (dataUnfinishedTickets) {
            if (dataUnfinishedTickets.length > 0) {
                setUnfinishedTicket(dataUnfinishedTickets[0]);
            } else {
                setIsFetchingUnfinishedTickets(false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCompetitions, dataUnfinishedTickets]);

    useEffect(() => {
        if (ticketQuestion && ticketResult) {
            setQuestionNumber(ticketQuestion);
            setLotteryResult(ticketResult);
            setCurrentTicket(unfinishedTicket);
            setIsFetchingUnfinishedTickets(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketQuestion, ticketResult]);

    useEffect(() => {
        resetDemoBetweenRounds();
        resetBetweenRounds();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.page}>
            <div className={styles.headingBox}>
                <h2 className={styles.heading}>{t('start.heading')}</h2>
                <p className={styles.subHeading}>{t('start.subHeading')}</p>
            </div>
        </div>
    );
}

export default Start;