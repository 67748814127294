import React from 'react';
import getWinningSymbol from "../../../utils/getWinningSymbol";
import styles from './PrizePlans.module.scss';
import {formatSpaceBetweenDigits} from "../../../utils/formatNumber";

interface TableHeader {
    tableHeader: string;
}

interface PrizeLevel {
    amount: number;
    value: number;
}

interface PrizePlanState {
    batchSize: number;
    prizeLevels: PrizeLevel[];
}

interface PrizePlansProps {
    prizePlans: PrizePlanState;
    tableHeaders: TableHeader[];
}

const PrizePlans = ({prizePlans, tableHeaders}: PrizePlansProps) => {

    return (
        <div className={styles.tableBox}>
            <table className={styles.table}>
                <thead>
                <tr className={styles.row}>
                    {tableHeaders &&
                        tableHeaders.map(({ tableHeader }) => {
                            return (
                                <th key={tableHeader} className={styles.tableHeader}>
                                    {tableHeader}
                                </th>
                            );
                        })}
                </tr>
                </thead>
                <tbody>
                {prizePlans.prizeLevels.length &&
                    prizePlans.prizeLevels.map(({ amount, value }) => {
                        return (
                            <tr className={styles.row} key={value}>
                                <td className={styles.tableData}>
                                    <img
                                        src={value ? getWinningSymbol(value).toString() : undefined}
                                        alt={value.toString()}
                                        className={styles.img}
                                        width={30}
                                        height={30}
                                    />
                                </td>
                                <td className={styles.tableData}>
                                {formatSpaceBetweenDigits(amount)}
                                </td>
                                {value && (
                                    <td
                                        className={styles.tableData}
                                    >{`$${formatSpaceBetweenDigits(value)}`}</td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PrizePlans;