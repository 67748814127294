import React, {useRef, useLayoutEffect, useEffect} from 'react';
import {func} from 'prop-types';
import styles from './CountdownAnim.module.scss';
import gsap from 'gsap';
import SoundManager from "../../../../utils/SoundManager";


function CountdownAnim({onAnimComplete}: any) {
    const element1 = useRef<HTMLDivElement>(null);
    const element2 = useRef<HTMLDivElement>(null);
    const element3 = useRef<HTMLDivElement>(null);
    const timeline = useRef(gsap.timeline());

    useLayoutEffect(() => {

        if (
            element1.current &&
            element2.current &&
            element3.current
        ) {

            timeline.current = gsap.timeline({
                onComplete: () => {
                    if (onAnimComplete) {
                        onAnimComplete();
                    }
                },
            });

            const tl = timeline.current;
            const hideYOffset = 4;

            // Hide all elements
            tl.set([element1.current, element2.current, element3.current], {
                opacity: 0,
                scale: 0.2,
            });

            // Element 1
            const dIn = 0.4;
            const dOut = 0.2;
            tl.to(element1.current, {
                opacity: 1,
                scale: 1,
                ease: 'back.out(1.5)',
                duration: dIn,
                delay: 0.6,
                onStart: () => {
                    SoundManager.instance.playVO('countdown');
                },
            });
            tl.to(element1.current, {
                opacity: 0,
                y: hideYOffset,
                duration: dOut,
                delay: 0.1,
            });

            // Element 2
            tl.to(element2.current, {
                opacity: 1,
                scale: 1,
                ease: 'back.out(1.5)',
                duration: dIn,
                delay: 0.1,
            });
            tl.to(element2.current, {
                opacity: 0,
                y: hideYOffset,
                duration: dOut,
                delay: 0.1,
            });

            // Element 3
            tl.to(element3.current, {
                opacity: 1,
                scale: 1,
                ease: 'back.out(1.5)',
                duration: dIn,
                delay: 0.1,
            });
            tl.to(element3.current, {
                opacity: 0,
                y: hideYOffset,
                duration: dOut,
                delay: 0.1,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        () => () => {
            if (timeline.current) {
                timeline.current.kill();
            }
        },
        []
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.imageWrapper} ref={element1}>
                <div className={styles.countdownNumber}>3s</div>
            </div>
            <div className={styles.imageWrapper} ref={element2}>
                <div className={styles.countdownNumber}>2s</div>
            </div>
            <div className={styles.imageWrapper} ref={element3}>
                <div className={styles.countdownNumber}>1s</div>
            </div>
        </div>
    );
}

CountdownAnim.propTypes = {
    onAnimComplete: func,
};

CountdownAnim.defaultProps = {
    onAnimComplete: null,
};

export default CountdownAnim;