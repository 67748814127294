import React, {useContext, useEffect, useState} from 'react';
import {node} from 'prop-types';
import {useQuery} from 'react-query';
import MathUtils from "../utils/MathUtils";
import apiClient from "../utils/apiClient";
import adjustOrderOfCoins from "../utils/adjustOrderOfCoins";
import {QuizContext} from "./QuizContext";

interface DemoContextProps {
    questionIndex: number;
    incrementQuestionIndex: () => void;
    isDemo: boolean | null;
    setIsDemo: React.Dispatch<React.SetStateAction<boolean | null>>;
    demoTicketResult: any;
    setDemoTicketResult: React.Dispatch<React.SetStateAction<any>>;
    demoQuizIsReady: boolean;
    demoQuestions: any;
    getQuestionCountdown: () => Promise<any>;
    getQuestion: () => Promise<any>;
    postAnswer: (data: any) => Promise<any>;
    demoQuizResultSummary: any;
    resetDemoBetweenRounds: () => void;
    refetchDemoQuiz: () => void;
    isFunMode: boolean;
    setIsFunMode: (val: boolean) => void;
    funModebalance: number | null;
    setFunModeBalance: (val: number) => void;
}

interface DemoQuestion {
    questionType: string;
    text: string;
    imageData: string;
    category: {
        id: number;
        name: string;
    };
    alternatives: {
        guid: string;
        text: string;
        isCorrect: boolean;
    }[];
}

interface QuestionScore {
    score: number;
    isCorrect: boolean;
    noAnswerReason: null;
    helperType: null;
    roundExpired: null;
}

interface DemoQuizResultSummary {
    questionScores: QuestionScore[];
    totalScore: number;
}

const initialDemoContextProps: DemoContextProps = {
    questionIndex: 0,
    incrementQuestionIndex: () => {},
    isDemo: null,
    setIsDemo: () => {},
    demoTicketResult: null,
    setDemoTicketResult: () => {},
    demoQuizIsReady: false,
    demoQuestions: null,
    getQuestionCountdown: async () => {},
    getQuestion: async () => {},
    postAnswer: async () => {},
    demoQuizResultSummary: null,
    resetDemoBetweenRounds: () => {},
    refetchDemoQuiz: () => {},
    isFunMode: false,
    setIsFunMode: () => {},
    funModebalance: null,
    setFunModeBalance: () => {},
};


const DemoContext = React.createContext<DemoContextProps>(initialDemoContextProps);

function DemoProvider({ children }: { children: React.ReactNode }) {
    const { setUserIsPlayingQuiz } = useContext(QuizContext);
    const [gameId, setGameId] = useState(null);
    const [isDemo, setIsDemo] = useState<boolean | null>(null);
    const [xSameDemoUrl, setXSameDemoUrl] = useState('');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [demoTicketResult, setDemoTicketResult] = useState(null);
    const [demoQuestions, setDemoQuestions] = useState<DemoQuestion[]>([]);
    const [demoQuizIsReady, setDemoQuizIsReady] = useState(false);
    const [timeStart, setTimeStart] = useState(new Date());
    const [demoQuizResultSummary, setDemoQuizResultSummary] = useState<DemoQuizResultSummary>({
        questionScores: [],
        totalScore: 0,
    });
    const [isFunMode, setIsFunMode] = useState(false);
    const [funModebalance, setFunModeBalance] = useState<number | null>(null);

    const stall = async (stallTime = 500) => {
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, stallTime));
    };

    const incrementQuestionIndex = () => {
        setQuestionIndex(questionIndex + 1);
    };

    const calculateQuestionScore = (
        timeAnswerQuestion: number,
        totalTime = 10000,
        maxScore = 5000
    ) => {
        let score = MathUtils.map(timeAnswerQuestion, totalTime, 0, 0, maxScore);
        score += 5000;

        return Math.round(score);
    };

    /**
     * Game ID
     */
    const getGameId = useQuery(
        'gameId',
        () => apiClient.get('api/Quiz/Competitions', {}),
        {
            enabled: !!isDemo,
            refetchOnWindowFocus: false,
            onSuccess: (response: any) => {
                if (response.length) {
                    setGameId(response[0].gameCollectionId);
                }
            },
            onError: (error) => {
                console.error('Error on fetching gameId in DemoContext : ', error);
            },
        }
    );

    /**
     * Demo Ticket
     */
    const getDemoTicket = useQuery(
        'demoTicket',
        () => apiClient.get(`api/Game/demo/${gameId}`, {}),
        {
            enabled: !!isDemo && !!gameId,
            refetchOnWindowFocus: false,
            onSuccess: (response: any) => {
                setXSameDemoUrl(response[0].url);
            },
            onError: (error) => {
                console.error('Error on fetching demoTicket in DemoContext: ', error);
            },
        }
    );

    /**
     * XsameDemo
     */
    const getXsameDemo = useQuery(
        'XsameDemo',
        () => apiClient.get(xSameDemoUrl, {}),
        {
            enabled: xSameDemoUrl !== '',
            refetchOnWindowFocus: false,
            onSuccess: (response: any) => {
                const data = {
                    ...response,
                    arrayLayout: adjustOrderOfCoins(response.layout, response.prizeValue),
                };
                console.log(data);
                setDemoTicketResult(data);
                if (isFunMode && funModebalance) {
                    const balanceAfterPurchase : number = funModebalance - 5;
                    setFunModeBalance(balanceAfterPurchase);
                }
            },
            onError: (error) => {
                console.error('Error on fetching data: ', error);
            },
        }
    );

    /**
     * Demo Questions
     */
    const getQuizQuestions = useQuery(
        'questions',
        () => apiClient.get('api/Quiz/Questions/demo', {}),
        {
            enabled: !!isDemo,
            refetchOnWindowFocus: false,
            onSuccess: (response: any) => {
                setDemoQuestions(response.questions);
            },
            onError: (error) => {
                console.error('Error on fetching demo questions : ', error);
            },
        }
    );

    /**
     * Refetch quiz data on click play again
     */

    const refetchDemoQuiz = () => {
        if (isDemo) {
            getGameId.refetch();
        }

        if (gameId) {
            getDemoTicket.refetch();
        }

        if (xSameDemoUrl) {
            getXsameDemo.refetch();
        }

        getQuizQuestions.refetch();
    };

    useEffect(() => {
        if (isDemo && demoQuestions.length > 0 && !!demoTicketResult) {
            setDemoQuizIsReady(true);
        }
    }, [isDemo, demoQuestions, demoTicketResult, demoQuizIsReady]);

    /**
     * Countdown
     * */

    const getQuestionCountdown = async () => {
        await stall(500);

        return {
            orderNumber: questionIndex + 1,
            questionsInTicket: 7,
            category: {...demoQuestions[questionIndex].category},
        };
    };

    /**
     * Question / Next question
     * */

    const getQuestion = async () => {
        await stall(500);
        const start = new Date();
        setTimeStart(start);

        const demoQuestion = demoQuestions[questionIndex];

        const alternatives = [];
        for (let i = 0; i < demoQuestion.alternatives.length; i++) {
            const alt = {
                guid: `${questionIndex}-${i}`,
                text: demoQuestion.alternatives[i].text,
                isCorrect: demoQuestion.alternatives[i].isCorrect,
            };
            alternatives.push(alt);
        }

        return {
            ticketId: questionIndex + 1,
            customerQuestionId: 1264,
            orderNumber: questionIndex + 1,
            questionsInTicket: 7,
            question: {
                questionType: demoQuestion.questionType,
                text: demoQuestion.text,
                imageData: demoQuestion.imageData,
                alternatives,
                usableHelperTypes: null,
            },
            timeLimitSeconds: 10,
            helpers: null,
            helperUsedOnTicket: false,
        };
    };

    /**
     * Demo question answer result
     */
    const postAnswer = async (data: any) => {
        let result;
        let score: number = 0;
        let isCorrect: boolean;
        const start = timeStart;
        const end = new Date();

        const timeAnswerQuestion = end.getTime() - start.getTime();

        const questionData = await getQuestion();

        const correctAlternative = questionData?.question.alternatives.find(
            (alternative) => alternative.isCorrect
        );

        if (data.answerGuid && correctAlternative) {
            if (correctAlternative.guid === data.answerGuid) {
                result = 'CorrectAnswer';
                score = calculateQuestionScore(timeAnswerQuestion);
                isCorrect = true;
            } else {
                result = 'WrongAnswer';
                score = 0;
                isCorrect = false;
            }
        }

        if (data.noAnswerReason && data.noAnswerReason === 'timeout') {
            result = 'Expired';
            score = 0;
            isCorrect = false;
        }

        const questionResult = {
            correctAnswer: correctAlternative?.guid,
            result,
            score,
        };

        setDemoQuizResultSummary((prevState) => ({
            ...prevState,
            questionScores: [
                ...prevState.questionScores,
                {
                    score,
                    isCorrect,
                    noAnswerReason: null,
                    helperType: null,
                    roundExpired: null,
                },
            ],
            totalScore: prevState.totalScore + questionResult.score,
        }));

        return questionResult;
    };

    const resetDemoBetweenRounds = () => {
        setGameId(null);
        setXSameDemoUrl('');
        setQuestionIndex(0);
        setDemoTicketResult(null);
        setDemoQuestions([]);
        setDemoQuizIsReady(false);
        setTimeStart(new Date());
        setDemoQuizResultSummary({ questionScores: [], totalScore: 0 });
        setIsDemo(false);
        setUserIsPlayingQuiz(false);
    };

    return (
        <DemoContext.Provider
            value={{
                questionIndex,
                incrementQuestionIndex,
                isDemo,
                setIsDemo,
                demoTicketResult,
                setDemoTicketResult,
                demoQuizIsReady,
                demoQuestions,
                getQuestionCountdown,
                getQuestion,
                postAnswer,
                demoQuizResultSummary,
                resetDemoBetweenRounds,
                refetchDemoQuiz,
                isFunMode,
                setIsFunMode,
                funModebalance,
                setFunModeBalance,
            }}
        >
            {children}
        </DemoContext.Provider>
    );
}

export { DemoContext, DemoProvider };

DemoProvider.propTypes = {
    children: node.isRequired,
};
