//  Game states
export const TOTAL_NUMBER_OF_QUESTIONS = 7;
export const TUTORIAL = 'howItWorks';
export const PURCHASE_VIEW = 'purchase';
export const COUNTDOWN_VIEW = 'countdown';
export const QUESTION_VIEW = 'question';
export const FLIP_COIN_VIEW = 'flipCoin';
export const RESULT_VIEW = 'result';
export const LOTTERY_RESULT_VIEW = 'lotteryResult';
export const WINNING_VIEW = 'winnings';
export const CONFIRMATION_VIEW = 'confirmation';
export const START_VIEW = 'start';
export const TOPSCORE_VIEW = 'topscore';
export const PRIZES_VIEW = 'prizes';
export const DEMO_VIEW = 'demo';

//  Modals
export const LOGIN: string = 'LOGIN';
export const WALLET: string = 'WALLET';
export const SESSION_TIMEOUT: string = 'SESSION_TIMEOUT';
export const ONBOARDING: string = 'ONBOARDING';
export const UNFINISHED: string = 'UNFINISHED';
export const ERROR: string = 'ERROR';
export const DEMO: string = 'DEMO';