import coin1 from '../assets/coins/small/bowlingball_bronze.png';
import coin1Large from '../assets/coins/large/bowlingball_bronze_large.png';
import coin2 from '../assets/coins/small/volleyball_bronze.png';
import coin2Large from '../assets/coins/large/volleyball_bronze_large.png';
import coin3 from '../assets/coins/small/billiardball_bronze.png';
import coin3Large from '../assets/coins/large/billiardball_bronze_large.png';
import coin4 from '../assets/coins/small/baseball_bronze.png';
import coin4Large from '../assets/coins/large/baseball_bronze_large.png';
import coin5 from '../assets/coins/small/basketball_bronze.png';
import coin5Large from '../assets/coins/large/basketball_bronze_large.png';
import coin6 from '../assets/coins/small/tennisball_bronze.png';
import coin6Large from '../assets/coins/large/tennisball_bronze_large.png';
import coin7 from '../assets/coins/small/golfball_bronze.png';
import coin7Large from '../assets/coins/large/golfball_bronze_large.png';
import coin8 from '../assets/coins/small/billiardball_silver.png';
import coin8Large from '../assets/coins/large/billiardball_silver_large.png';
import coin9 from '../assets/coins/small/baseball_silver.png';
import coin9Large from '../assets/coins/large/baseball_silver_large.png';
import coin10 from '../assets/coins/small/basketball_silver.png';
import coin10Large from '../assets/coins/large/basketball_silver_large.png';
import coin11 from '../assets/coins/small/tennisball_silver.png';
import coin11Large from '../assets/coins/large/tennisball_silver_large.png';
import coin12 from '../assets/coins/small/golfball_silver.png';
import coin12Large from '../assets/coins/large/golfball_silver_large.png';
import coin13 from '../assets/coins/small/tennisball_gold.png';
import coin13Large from '../assets/coins/large/tennisball_gold_large.png';
import coin14 from '../assets/coins/small/golfball_gold.png';
import coin14Large from '../assets/coins/large/golfball_gold_large.png';
import coin15 from '../assets/coins/small/soccerball_gold.png';
import coin15Large from '../assets/coins/large/soccerball_gold_large.png';

interface PrizeMap {
  [key: number]: string;
}

const prizes: PrizeMap = {
  1: coin1,
  2: coin2,
  3: coin3,
  5: coin4,
  10: coin5,
  20: coin6,
  25: coin6,
  50: coin7,
  100: coin8,
  200: coin8,
  500: coin9,
  1000: coin10,
  2500: coin11,
  5000: coin12,
  10000: coin13,
  25000: coin14,
  50000: coin14,
  100000: coin15,
  1000000: coin15,
};

const largeImagePrizes: PrizeMap = {
  1: coin1Large,
  2: coin2Large,
  3: coin3Large,
  5: coin4Large,
  10: coin5Large,
  20: coin6Large, // 20 is not in the original prizes object
  25: coin6Large,
  50: coin7Large,
  100: coin8Large,
  200: coin8Large, // 200 is not in the original prizes object
  500: coin9Large,
  1000: coin10Large,
  2500: coin11Large,
  5000: coin12Large,
  10000: coin13Large,
  25000: coin14Large,
  50000: coin14Large, // 50000 is not in the original prizes object
  100000: coin15Large,
  1000000: coin15Large, // 1000000 is not in the original prizes object
};

const getWinningSymbol = (value = 10, largeImg = false) => {
  if (largeImg) {
    return largeImagePrizes[value];
  }
  return prizes[value];
};

export default getWinningSymbol;
