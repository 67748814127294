import {useContext} from "react";
import Button from '../elements/Button/Button';
import {useTranslation} from "react-i18next";

import {ReactComponent as SeeTopScoreIcon} from '../../assets/icons/top-scores-icon.svg';
import {ReactComponent as SeePrizesIcon} from '../../assets/icons/prizes-icon.svg';
import styles from './BottomMenu.module.scss'
import {QuizContext} from "../../contexts/QuizContext";
import {DEMO, PRIZES_VIEW, PURCHASE_VIEW, TOPSCORE_VIEW} from "../../config/constants";
import {AppContext} from "../../contexts/AppContext";
import {DemoContext} from "../../contexts/DemoContext";
import clsx from "clsx";
import SoundManager from "../../utils/SoundManager";

interface Props {
    isQuizView: boolean;
    isAbsent: boolean;
    onClick?: () => void;
    isDisabled?: boolean;
    isFetchingUnfinishedTickets?: boolean;
}

export default function BottomMenu({isQuizView, isAbsent, onClick, isDisabled}: Props) {
    const {t} = useTranslation();
    const {setCurrentView} = useContext(QuizContext);
    const {tokenData, setCurrentModal} = useContext(AppContext);
    const {setIsDemo} = useContext(DemoContext);

    const startDemoPlay = async () => {
        SoundManager.instance.playVO('button');
        setIsDemo(true);
        setCurrentModal(DEMO);
    }

    if (isAbsent) {
        return null;
    }

    if (isQuizView && !isAbsent) {
        return (
            <div className={styles.quizMenuBox}>
                <div className={styles.quizLinkBox}>
                    <Button className={styles.topscoreButton}
                            onClick={() => {
                                SoundManager.instance.playVO('button');
                                setCurrentView(TOPSCORE_VIEW);
                            }}
                            isTertiary>
                        {t('start.seeTopScoreButton')}{' '}
                        {<SeeTopScoreIcon/>}
                    </Button>
                    <Button className={styles.prizesButton}
                            onClick={() => {
                                SoundManager.instance.playVO('button');
                                setCurrentView(PRIZES_VIEW)
                            }}
                            isTertiary>
                        {t('start.seePrizesButton')}{' '}
                        {<SeePrizesIcon/>}
                    </Button>
                </div>
                <div className={styles.quizButtonBox}>
                    <Button onClick={onClick} isDisabled={isDisabled} className={styles.quizPlayButton} isBuyButton>
                        {t('quiz.purchase.playButton')}
                        <p className={styles.quizButtonGameName}>{t('quiz.purchase.gameName')}</p>
                    </Button>
                </div>
            </div>
        )
    } else if (!isQuizView && !isAbsent) {
        return (
            <div className={clsx(styles.menuBox)}>
                <div className={styles.linkBox}>
                    <Button className={styles.topscoreButton}
                            onClick={() => {
                                SoundManager.instance.playVO('button');
                                setCurrentView(TOPSCORE_VIEW)
                            }}
                            isTertiary>
                        {t('start.seeTopScoreButton')}{' '}
                        {<SeeTopScoreIcon/>}
                    </Button>
                    <Button className={styles.prizesButton}
                            onClick={() => {
                                SoundManager.instance.playVO('button');
                                setCurrentView(PRIZES_VIEW)
                            }}
                            isTertiary>
                        {t('start.seePrizesButton')}{' '}
                        {<SeePrizesIcon/>}
                    </Button>
                </div>
                <div className={styles.buttonBox}>
                    {tokenData?.access_token &&
                        <Button onClick={() => {
                            SoundManager.instance.playVO('button');
                            setCurrentView(PURCHASE_VIEW)
                        }}
                                className={styles.playButton}>
                            {t('start.playButton')}
                            <p className={styles.buttonGameName}>{t('start.gameName')}</p>
                        </Button>
                    }
                    <Button onClick={(startDemoPlay)} isSecondary>
                        {t('start.demoButton')}
                    </Button>
                </div>
            </div>
        )
    } else {
        return null;
    }
}