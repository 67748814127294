import React, {useContext, useEffect, useRef, useState, memo, useCallback} from 'react';
import clsx from "clsx";
import {gsap} from 'gsap';
import {QuizContext} from "../../../../contexts/QuizContext";
import {LOTTERY_RESULT_VIEW, WINNING_VIEW} from "../../../../config/constants";
import {DemoContext} from "../../../../contexts/DemoContext";
import {AppContext} from "../../../../contexts/AppContext";
import front from '../../../../assets/coins/coin/coin-flip-front.png'
import getWinningSymbol from "../../../../utils/getWinningSymbol";
import styles from './FlipAllCoins.module.scss';
import SoundManager from "../../../../utils/SoundManager";
import formatCurrency from "../../../../utils/formatCurrency";

type CoinProps = {
    coin: any;
    index: number;
    onClickCoin: (index: number) => void;
    flippedCoins: any[];
    coinAnimationRefs: React.MutableRefObject<(HTMLImageElement | null)[]>;
};

const Coin = memo(({coin, index, onClickCoin, flippedCoins, coinAnimationRefs}: CoinProps) => {
    const coinRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        coinAnimationRefs.current[index] = coinRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.coin} key={index}>
            <p className={clsx(styles.coinFlipText, {
                [styles.isFlipped]: flippedCoins[index],
            })}>
                {`${formatCurrency(coin)}`}
            </p>
            {flippedCoins[index] ?
                <img
                    src={getWinningSymbol(coin, true)}
                    alt="Coin"
                /> :
                <img ref={coinRef} onClick={() => onClickCoin(index)} src={front} alt={'Coin'}/>
            }
        </div>
    );
});

function FlipAllCoins() {
    const {
        lotteryResult,
        coins,
        setCurrentView,
        currentTicket,
    } = useContext(QuizContext);
    const {isDemo} = useContext(DemoContext);
    const {isReplay} = useContext(AppContext);

    const [currentCoins] = useState(lotteryResult.arrayLayout);
    const [flippedCoins, setFlippedCoins] = useState<any>([]);
    const [isFlipping, setIsFlipping] = useState<boolean[]>(new Array(currentCoins.length).fill(false));

    const coinAnimationRefs = useRef<(HTMLImageElement | null)[]>([]);

    const onClickFlipCoin = (coinIndex: number) => {
        gsap.to(coinAnimationRefs.current[coinIndex], {
            rotation: 360,
            opacity: 0,
            duration: 0.5,
            onStart: () => {
                const newFlippedCoins = [...flippedCoins];
                newFlippedCoins[coinIndex] = currentCoins[coinIndex];
                setFlippedCoins(newFlippedCoins);
            },
            onComplete: () => {
                gsap.to(coinAnimationRefs.current[coinIndex], {
                    rotation: 720,
                    opacity: 1,
                    duration: 0.6,
                });
            },
        });
    };

    const onClickCoin = useCallback((coinIndex: number) => {
        if (!isFlipping[coinIndex]) {
            setIsFlipping(prevState => {
                const newState = [...prevState];
                newState[coinIndex] = true;
                return newState;
            });
            requestAnimationFrame(() => {
                onClickFlipCoin(coinIndex);
                SoundManager.instance.playVO('coin-flip');
            });
        }
        return currentCoins[coinIndex];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFlipping, onClickFlipCoin]);

    useEffect(() => {
        if (flippedCoins.length === currentCoins.length && flippedCoins.every((coin: undefined) => coin !== undefined)) {
            setTimeout(() => {
                if (!isDemo && currentTicket.tickets[0].isWinner) {
                    setCurrentView(WINNING_VIEW);
                } else if (isDemo && lotteryResult.prizeValue > 0) {
                    setCurrentView(WINNING_VIEW);
                } else {
                    setCurrentView(LOTTERY_RESULT_VIEW);
                }
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flippedCoins, coins, setCurrentView]);

    useEffect(() => {
        if (isReplay) {
            setFlippedCoins(currentCoins);
        }
    }, [isReplay, currentCoins]);

    return (
        <div className={styles.page}>
            <div className={styles.headingBox}>
                <h2 className={styles.heading}>Flip All Coins</h2>
            </div>

            <div className={styles.coinFlipBox}>
                {currentCoins.map((coin: any, index: number) => (
                    <Coin key={index} coin={coin} index={index} onClickCoin={onClickCoin} flippedCoins={flippedCoins} coinAnimationRefs={coinAnimationRefs}/>
                ))}
            </div>
        </div>
    );
}

export default FlipAllCoins;