export default function adjustOrderOfCoins(layout: any, prizeValue: any) {
  if (!layout) return [];
  const coinsArray = layout
    .split(';')
    .map((item: any) => Number(item.replace(',000', '')));
  if (prizeValue > 0) {
    if (coinsArray[coinsArray.length] === prizeValue) {
      return coinsArray;
    }
    let index;
    for (let i = 0; i < coinsArray.length; i++) {
      if (coinsArray[i] === prizeValue) {
        index = i;
      }
    }
    coinsArray.splice(index, 1);
    coinsArray.push(prizeValue);
    return coinsArray;
  }
  return coinsArray;
}
