import {motion} from "framer-motion";
import {useTranslation} from "react-i18next";
import styles from '../Menu.module.scss'
import {useContext} from "react";
import {QuizContext} from "../../../contexts/QuizContext";
import {
    DEMO,
    DEMO_VIEW,
    PRIZES_VIEW,
    PURCHASE_VIEW,
    TOPSCORE_VIEW,
    TUTORIAL
} from "../../../config/constants";
import {DemoContext} from "../../../contexts/DemoContext";
import {AppContext} from "../../../contexts/AppContext";
import SoundManager from "../../../utils/SoundManager";
import Button from "../../elements/Button/Button";

interface LinkProps {
    name: string;
    key: string;
    view: string;
}

interface Props {
    isPlayVisible: boolean;
    handleClick: () => void;
}

export default function Links({isPlayVisible, handleClick}: Props) {
    const {t} = useTranslation();
    const {setCurrentView} = useContext(QuizContext);
    const {setIsDemo} = useContext(DemoContext);
    const {setCurrentModal, appIsMuted, setAppIsMuted} = useContext(AppContext);

    const boxVariant = {
        closed: {
            opacity: 0,
        },
        open: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.05,
            },
        },
    };

    const listVariant = {
        closed: {
            opacity: 0,
        },
        open: {
            opacity: 1,
        },
    };

    const onClickDemoPlay = () => {
        setIsDemo(true);
        setCurrentModal(DEMO);
    }

    const links: LinkProps[] = [
        {name: t("menu.play"), key: "quiz", view: PURCHASE_VIEW},
        {name: t("menu.tryDemo"), key: "tryDemo", view: DEMO_VIEW},
        {name: t("menu.topScores"), key: "topScores", view: TOPSCORE_VIEW},
        {name: t("menu.prizes"), key: "prizes", view: PRIZES_VIEW},
        {name: t("menu.howItWorks"), key: "howItWorks", view: TUTORIAL},
    ];

    return (
        <motion.div
            className={styles.menuItems}
            variants={boxVariant}
            initial="closed"
            animate="open"
            exit="closed"
        >
            {isPlayVisible && (
                <motion.span
                    className={styles.menuItem}
                    key="quiz"
                    onClick={() => {
                        SoundManager.instance.playVO('menu-button');
                        setCurrentView(PURCHASE_VIEW)
                        handleClick()
                    }}
                    variants={listVariant}
                >
                    {t("menu.play")}
                </motion.span>
            )}
            {links
                .filter(link => link.key !== "quiz")
                .map(link => (
                    <motion.span
                        className={styles.menuItem}
                        key={link.key}
                        onClick={() => {
                            if (link.key === "tryDemo") {
                                onClickDemoPlay();
                            } else if (setCurrentView) {
                                setCurrentView(link.view);
                            }
                            SoundManager.instance.playVO('menu-button');
                            handleClick()
                        }}
                        variants={listVariant}
                    >
                        {link.name}
                    </motion.span>
                ))
            }
            <motion.span className={styles.soundButtonContainer} variants={listVariant}>
            <Button
                isTertiary
                className={styles.soundButton}
                onClick={() => {
                    setAppIsMuted(!appIsMuted);
                }}
            >
                {appIsMuted
                    ? t('soundButton.soundOff')
                    : t('soundButton.soundOn')}
            </Button>
            </motion.span>
        </motion.div>
    );
};
