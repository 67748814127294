import React from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";

import RSLogo from '../../assets/logo/logo-rs.png';

import styles from './TurnDeviceScreen.module.scss'

const TurnDeviceScreen: React.FC = () => {
  const {t} = useTranslation();

  return (
    <div className={clsx(styles.turnDeviceScreen)}>
      <p className={styles.turnDeviceText}>{t('doNotSupportLandscape')}</p>
      <img className={styles.turnDeviceLogo} src={RSLogo} alt={'RS'}/>
    </div>
  );
};

export default TurnDeviceScreen;
