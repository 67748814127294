import React from 'react';
import {formatSpaceBetweenDigits} from "../../../utils/formatNumber";
import styles from './Leaderboard.module.scss';
import {getUserAvatar} from "../../../utils/getAvatar";

interface Leader {
    rank: number;
    avatarId: string;
    nickname: string;
    score: number;
}

interface LeaderboardProps {
    leaders: Leader[];
}

const Leaderboard = ({leaders}: LeaderboardProps) => {

    return (
            <table className={styles.table}>
                <tbody>
                {leaders && leaders.length > 0 &&
                    leaders.map(({ rank,avatarId, nickname, score }) => {
                        return (
                            <tr className={styles.row} key={rank}>
                                <td className={styles.rank}>
                                    {formatSpaceBetweenDigits(rank)}
                                </td>
                                <td className={styles.nickname}>
                                    <img className={styles.avatar} src={getUserAvatar(Number(avatarId))} alt={avatarId}/>
                                    {nickname}
                                </td>
                                    <td className={styles.score}>
                                        {score && formatSpaceBetweenDigits(score)}
                                    </td>
                            </tr>
                        );
                    })}

                {(!leaders || leaders.length === 0) && (
                        <p className={styles.empty}>
                            No records found
                        </p>
                )}
                </tbody>
            </table>
    );
};

export default Leaderboard;