import React, {useEffect} from 'react';
import tutorialHelpers from '../../assets/helpers/tutorial-helpers.png';
import styles from './Tutorial.module.scss'
import {useTranslation} from "react-i18next";

function Tutorial() {
    const {t} = useTranslation();

    useEffect(() => {
        localStorage.setItem('hasViewedTutorial', 'true');
    }, []);

    return (
        <div className={styles.page}>
            <div className={styles.tutorialBox}>

                <div className={styles.headingBox}>
                    <h2 className={styles.heading}>{t('tutorial.heading').toUpperCase()}</h2>
                </div>

                <div className={styles.firstSection}>
                    <h2 className={styles.firstHeading}>{t('tutorial.firstHeading')}</h2>
                    <p className={styles.firstParagraph}>{t('tutorial.firstParagraph')}</p>
                </div>

                <div className={styles.secondSection}>
                    <h2 className={styles.secondHeading}>{t('tutorial.secondHeading')}</h2>
                    <p className={styles.secondParagraph}>{t('tutorial.secondParagraph')}</p>
                    <img className={styles.tutorialImage} src={tutorialHelpers} alt='helpers'/>
                    <p className={styles.secondParagraph1}>{t('tutorial.secondParagraph1')}</p>
                </div>

                <div className={styles.thirdSection}>
                    <h2 className={styles.thirdHeading}>{t('tutorial.thirdHeading')}</h2>
                    <p className={styles.thirdParagraph}>{t('tutorial.thirdParagraph')}</p>
                </div>

            </div>
        </div>
    );
}

export default Tutorial;